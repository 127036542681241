import React from 'react'
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component'
import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component'
import { isImage, isVideo, isFacebookVideo } from '../../../../utils/Helper_functions/VideoAndImage'
import FacebookVideoEmbed from '../../../utilComponent/FbEmbedVideo/FbEmbedVideo.utilComponents'
import { Section2Container, TextSection } from './section2.style'
import VideoPlayer from '../../../utilComponent/VideoComponent/VideoPlayer.utilcomponent'
export default function Section2({ data }) {
  return (
    <Section2Container
      id="sec2">
      <div className='Left_section'>
        <LiveEditImageHighLighter
          section="sec2"
          type="img ">
          <VideoPlayer data={data.img} aspectRatio={'1107/749'} />
        </LiveEditImageHighLighter>
      </div>

      <div className="Right_section slideInLeft">
        <h2 className='heading' style={{ color: `${data.color}`, fontFamily: 'Montserrat' }}>
          <LiveEdithTextHighLighter
            section="sec2"
            type="text">
            {data?.text}
          </LiveEdithTextHighLighter>
        </h2>
        <LiveEdithTextHighLighter section='sec2' type='description' >
          <TextSection $color={data.color} style={{fontFamily:"Montserrat"}} dangerouslySetInnerHTML={{ __html: data.description }} >
          </TextSection>
        </LiveEdithTextHighLighter>
        <LiveEdithTextHighLighter section='sec2' type='description2'>
          <TextSection $color={data.color} style={{fontFamily:"Montserrat"}} dangerouslySetInnerHTML={{ __html: data.description2 }} >
          </TextSection>
        </LiveEdithTextHighLighter>

      </div>
    </Section2Container>
  )
}

