/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/agentshades logo.png';
import './Header.css';
import EditorImage from './Images/ic_round-edit.svg';
import { useNavigate } from 'react-router-dom';
export default function Header() {
  const [templateId, setTemplateId] = useState();
  const [username, setUsername] = useState('');
  const Navigate = useNavigate();
  useEffect(() => {
    const getTemplateId = async () => {
      const token = Cookies.get('token');
      const fetchTemplate = await axios.get('/api/get-active-template', {
        headers: {
          'x-access-token': token,
        },
      });
      setTemplateId(fetchTemplate.data.data.activetemplate);
    };
    const fetchUsername = () => {
      const user = Cookies.get('firstname');
      if (user) {
        setUsername(user);
      }
    }
    getTemplateId();
    fetchUsername();
  }, []);
  return (
    <div className="DashBoard_Header_container d-flex align-items-center justify-content-between">
      <Link to="/">
        <img
          src={Logo}
          alt="Logo"
          className="Logo"
        />
        AGENTSHADES | DASHBOARD
      </Link>
      <div className="d-flex gap-3 align-items-center">
        {username && (
          <p className="m-0" style={{ fontSize: '16px', fontWeight: 'bold', color: 'white' }}>
            Welcome, {username}!
          </p>
        )}
        <Link
          to={templateId !== null ? `/designer/${templateId}` : `/design`}
          className="Editor d-flex align-items-center gap-2"
          style={{ padding: '10px 23px' }}>
          <p
            className="m-0"
            style={{ fontSize: '18px' }}>
            Website Editor
          </p>
          <div className="wrapper">
            <img
              src={EditorImage}
              alt="Editor"
              style={{ width: '22px', marginTop: '-5px' }}
            />
          </div>
        </Link>
        {/* <div */}
        {/*   onClick={() => Navigate('../../domain_instructions')} */}
        {/*   className="headerButton d-flex justify-content-center align-items-center publish-btn" */}
        {/*   style={{ */}
        {/*     paddingBottom: '11px', */}
        {/*     paddingRight: '10px', */}
        {/*     cursor: 'pointer', */}
        {/*   }}> */}
        {/*   <p>Publish</p> */}
        {/*   <div className="svgWrapper"> */}
        {/*     <svg */}
        {/*       xmlns="http://www.w3.org/2000/svg" */}
        {/*       width="32" */}
        {/*       height="33" */}
        {/*       viewBox="0 0 32 33" */}
        {/*       fill="none"> */}
        {/*       <path */}
        {/*         d="M12.4134 9.44661C12.2898 9.56997 12.1917 9.71648 12.1248 9.87778C12.0579 10.0391 12.0234 10.212 12.0234 10.3866C12.0234 10.5612 12.0579 10.7341 12.1248 10.8954C12.1917 11.0567 12.2898 11.2033 12.4134 11.3266L17.5867 16.4999L12.4134 21.6733C12.1641 21.9226 12.024 22.2607 12.024 22.6133C12.024 22.9658 12.1641 23.304 12.4134 23.5533C12.6627 23.8026 13.0008 23.9426 13.3534 23.9426C13.7059 23.9426 14.0441 23.8026 14.2934 23.5533L20.4134 17.4333C20.537 17.3099 20.635 17.1634 20.7019 17.0021C20.7688 16.8408 20.8033 16.6679 20.8033 16.4933C20.8033 16.3187 20.7688 16.1457 20.7019 15.9844C20.635 15.8232 20.537 15.6766 20.4134 15.5533L14.2934 9.43328C13.7867 8.92661 12.9334 8.92661 12.4134 9.44661Z" */}
        {/*         fill="#999999" */}
        {/*       /> */}
        {/*     </svg> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
