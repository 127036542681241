
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import SeoMannagerHeader from './SeoHeader/SeoMannagerHeader';
import SeoListting from './SeoListting/SeoListting';
import './Seomanager.css';


export default function Seomanager() {
    const [seoData, setSeoData] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [viewData, setViewData] = useState(null);
    const [editData, setEditData] = useState(null);
  
    useEffect(() => {
      const fetchSeoData = async () => {
        try {
          const token = Cookies.get("token");
          const response = await axios.get("/admin/get-all-seo-entries", {
            headers: { "x-access-token": token },
          });
          if (response.data && response.data.status) {
            setSeoData(response.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching SEO data:", error);
          setSeoData([]);
        }
      };
  
      fetchSeoData();
    }, []);
  
    const handleAddSeo = () => {
      setIsFormVisible(true);
      setEditData(null);
    };
  
    const handleSaveSeo = async (newData, isEdit = false) => {
      try {
        const token = Cookies.get("token");
        if (isEdit) {
          await axios.put(`/admin/edit-seo-entry/${newData._id}`, newData, {
            headers: { "x-access-token": token },
          });
        }
        setSeoData((prevData) =>
          isEdit
            ? prevData.map((entry) => (entry.id === newData._id ? { ...entry, ...newData } : entry))
            : [...prevData, newData]
        );
      } catch (error) {
        console.error("Error saving SEO data:", error);
      }
      setIsFormVisible(false);
    };
  
    const handleView = (data) => {
      setViewData(data);
    };
  
    const handleEdit = (data) => {
      setEditData({ ...data });
      setIsFormVisible(true);
    };
  
    const handleCloseModal = (e) => {
      if (e.target.className === "modal-overlay") {
        setViewData(null);
      }
    };
  
    return (
      <div className="Container">
        <SeoMannagerHeader onAddSeo={handleAddSeo} />
        <div className="seo_mannager">
          <div className="seo_mannager_header">
            <p className="heading" style={{ marginLeft: "15px" }}>URL</p>
            <p className="heading bar">Description</p>
            <p className="heading bar">Keywords</p>
            <p className="heading bar">Actions</p>
          </div>
  
          <div className="seo_mannager_list">
            {seoData.length > 0 ? (
              seoData.map((entry, index) => (
                <div key={index} className="seo_listting_info">
                  <p className="row_data">{entry.url}</p>
                  <p className="row_data bar">{entry.description}</p>
                  <p className="row_data bar">{entry.keywords}</p>
                  <div className="row_data bar actions">
                    <button onClick={() => handleView(entry)}>View</button>
                    <button onClick={() => handleEdit(entry)}>Edit</button>
                  </div>
                </div>
              ))
            ) : (
              <p className="no_data">No SEO data available</p>
            )}
          </div>
        </div>
  
        {isFormVisible && (
          <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content">
              <h2>{editData ? "Edit SEO Entry" : "Add SEO Entry"}</h2>
              <SeoListting
                seoData={seoData}
                onSave={handleSaveSeo}
                onClose={() => setIsFormVisible(false)}
                editData={editData}
              />
              <button className="close-btn" onClick={() => setIsFormVisible(false)}>
                Close
              </button>
            </div>
          </div>
        )}
  
        {viewData && (
           <div className="view-modal-overlay" onClick={handleCloseModal}>
           <div className="view-modal-content">
             <h3>SEO DETAILS</h3>
             <p><strong>Description:</strong> {viewData.description}</p>
             <p><strong>Keywords:</strong> {viewData.keywords}</p>
             <p><strong>URL:</strong> {viewData.url}</p>
             <button onClick={() => setViewData(null)}>Close</button>
           </div>
         </div>
        )}
      </div>
    );
  }
  





