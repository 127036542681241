/** @format */

import axios from "axios";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GetTemplateData from '../../../utils/apis/GetTemplateData.api';

import ClientReview from '../Components/client_reviews/client_reviews.component';
import NavbarT3 from '../Components/navbar/navbar.t3';
import OurTeam from '../Components/our_team/our_team.component';

import Cookies from "js-cookie";
import { useMessageContext } from '../../../context/Message.context';
import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import { isImage, isVideo } from '../../../utils/Helper_functions/VideoAndImage';
import FooterT3 from '../footer/footer.t3';
import {
  AgentAchievement,
  AgentStory, HeroContainer
} from './about_us.style';
import { HelmetSEO } from "../../../utils/HelmetSEO/HelmetSEO.utilcomponent";


interface Seolisting {
  _id: string;
  title: string;
  keywords: string;
  description: string;
  url: string;
}

export default function AboutUST3({ templateData }) {
  const [data, setData] = useState(null);
  const [footerData, setFooterData] = useState(null)
  const [profile, setProfile] = useState(null);
  const [Seo, setSeo] = useState<Seolisting[]>([]);
  const [seoHomeData, setSeoHomeData] = useState<Seolisting | null>(null);
  const { preview } = useParams();
  const token = Cookies.get("token");
  const { setTheme } = useMessageContext();
  //local Data
  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, 3, 2);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            borderRadius: '16px',
            warning_message: {
              fontFamily: 'Oswald',
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '23.4px',
            },
            warning_text: {
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '22.32px',
              textAlign: 'center',
            },
            button: {
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '23.4px',
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, [templateData]);
  useEffect(() => {
    const getFooterData = async () => {
      const data = await GetTemplateData(preview, 3, 1)
      if (data) {
        setFooterData(data)
      }
    }
    getFooterData()
  }, [])

  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      const agentUrl = window.location.href.split("/")[2];
      let config = {
        headers: {},
        params: {},
      };
      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }
      try {
        const profileData = await axios.get("/api/get-agent-profile", config);
        setProfile(profileData.data.data);

      } catch (e) {
        throw new Error(e);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const FetchSeo = async () => {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];
      let config = {
        headers: {},
        params: {},
      };

      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }

      try {
        const fetchData = await axios.get("/api/get-all-seo-entries", config);
        const fetchedData = fetchData.data.data;
        const homeSeoEntry = fetchedData.find((entry) => entry.url.includes('about'));
        setSeoHomeData(homeSeoEntry || null);
      } catch (e) {
        console.error("Error fetching seo listings:", e);
      }
    }

    FetchSeo();
  }, []);
  const defaultDescription = `Detailed REALTOR® profile for ${profile?.name || 'Agent'} , real estate agent at ROYAL STAR REALTY INC. in BRAMPTON, Ontario.`;
  const defaultKeywords = "real estate listings, homes for sale, homes for rent, property listings";


  if (!data)
    return (
      <div
        id="loadingScreen"
        className="loading-screen">
        <div className="loader"></div>
      </div>
    );
  const theme = {
    colors: {
      primary: data.styles[0].css.primary,
      text: data.styles[0].css.secondary,
      TextGrey: '#6E6E73',
      white: '#FDFDFE',
      InactiveGrey: '#b2b2b2',
      text2: '#000000',
      background: '#f8f9fa',
    },
  };
  return (
    <>
      <HelmetSEO seoHomeData={seoHomeData} profile={profile} defaultDescription={defaultDescription} defaultKeywords={defaultKeywords} />
      <ThemeProvider theme={theme}>
        <NavbarT3 data={data.header} />
        {data.sec1.visible && (
          <HeroContainer
            id="sec1"
            $color={data.sec1.color}>
            <img
              src={`/public/${data.sec1.image}`}
              alt="presentation"
            />
            <div className="right_section">
              <div className="above_section">
                <LiveEdithTextHighLighter
                  section="sec1"
                  type="text1">
                  <div
                    dangerouslySetInnerHTML={{ __html: data.sec1.text1 }}
                    className="header"></div>
                </LiveEdithTextHighLighter>
                <LiveEdithTextHighLighter
                  section="sec1"
                  type="description">
                  <div
                    dangerouslySetInnerHTML={{ __html: data.sec1.description }}
                    className="sub_test"></div>
                </LiveEdithTextHighLighter>
              </div>
            </div>
          </HeroContainer>
        )}
        {data.sec2.visible && (
          // <AgentAchievement id="sec2">
          //   {data.sec2.cards.map((data, i) => (
          //     <CardOfAchievement key={i}>
          //       <p className="header">
          //         <LiveEdithTextHighLighter
          //           section="sec2"
          //           type={`text${i}`}>
          //           {data.text}
          //         </LiveEdithTextHighLighter>
          //       </p>
          //       <p className="description">
          //         <LiveEdithTextHighLighter
          //           section="sec2"
          //           type={`description${i}`}>
          //           {data.description}
          //         </LiveEdithTextHighLighter>
          //       </p>
          //     </CardOfAchievement>
          //   ))}
          // </AgentAchievement>
          <AgentAchievement id="sec2" style={{
            background: `black url(/public/${data.sec2.image}) no-repeat center center`,
            backgroundAttachment: 'fixed'
          }} >
            <h1>{data.sec2.text}</h1>
            <div className="box-container">
              <div className="box">{data.sec2.text2} <p> {data.sec2.description2}</p></div>
              <div className="box">{data.sec2.text3}<p> {data.sec2.description3}</p></div>
              <div className="box">{data.sec2.text4}<p>{data.sec2.description4}</p></div>
            </div>
          </AgentAchievement>
        )}
        {data.sec3.visible && (
          <AgentStory id="sec3">
            <div className="left_section">
              <img
                src={`/public/${data.sec3.image}`}
                alt=""
              />
            </div>
            <div className="right_section">
              <p className="header">
                <LiveEdithTextHighLighter
                  section="sec3"
                  type="text">
                  {data.sec3.text}
                </LiveEdithTextHighLighter>
              </p>
              <p className="description mb-2">
                <LiveEdithTextHighLighter
                  section={'sec3'}
                  type={'description'}>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.sec3.description }}
                    className="sub_header"></div>
                </LiveEdithTextHighLighter>
              </p>
              <p className="description">
                <LiveEdithTextHighLighter
                  section={'sec3'}
                  type={'description2'}>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.sec3.description2 }}
                    className="sub_header"></div>
                </LiveEdithTextHighLighter>
              </p>
            </div>
          </AgentStory>
        )}
        {data.sec4.visible && <OurTeam data={data.sec4} />}
        {data.sec5.visible && <ClientReview data={data.sec5} />}
        {data.sec6.visible && (
          <HeroContainer
            id="sec6"
            $color={data.sec6.color}>
            {isImage(data.sec6?.image) ? (
              <img
                src={`/public/${data.sec6?.image}`}
                alt="HomeImage"
                loading="lazy"
                className="backgroundImage"
              />
            ) : isVideo(data.sec6?.image) ? (
              <video
                src={`/public/${data.sec6?.image}`}
                className="backgroundImage"
                autoPlay
                loop
                muted
              />
            ) : (
              <iframe
                className="backgroundImage"
                src={`https://www.youtube.com/embed/${data.sec6?.image}?autoplay=1&mute=1&loop=1&playlist=${data.sec6?.image}&controls=0&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  width: '100%', objectFit: 'cover',
                  border: 'none', height: '56vw'
                }}></iframe>
            )}
            <div className="right_section">
              <div className="above_section">
                <LiveEdithTextHighLighter
                  section="sec6"
                  type="text1">
                  <div
                    dangerouslySetInnerHTML={{ __html: data.sec6.text1 }}
                    className="header"></div>
                </LiveEdithTextHighLighter>
                <LiveEdithTextHighLighter
                  section="sec6"
                  type="description">
                  <div
                    dangerouslySetInnerHTML={{ __html: data.sec6.description }}
                    className="sub_test"></div>
                </LiveEdithTextHighLighter>
              </div>
            </div>
          </HeroContainer>
        )}

        <FooterT3 data={footerData} />
      </ThemeProvider>
    </>
  );
}
