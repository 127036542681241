/** @format */

import { useNavigate } from 'react-router-dom';
import { Container } from '../herosectionhome/herosectionhome.style';

import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import {
  isImage,
  isVideo,
} from '../../../../utils/Helper_functions/VideoAndImage';

type Section6Props = {
  data?: Record<string, any>;
};

export default function Section6({ data }: Section6Props) {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`../idxlisting/`);
  };

  return (
    <Container
      id="sec9"
      $color={data.color}
      className="sec1">
      <div className="HomeContainer">
        <div className="overlay"></div>
        <div className="leftSection">
          <LiveEdithTextHighLighter
            section="sec10"
            type="text">
            <div
              className="Text"
              dangerouslySetInnerHTML={{ __html: data.text }}></div>
            {/* <h1>{data?.text}</h1> */}
          </LiveEdithTextHighLighter>
          <LiveEdithTextHighLighter
            section="sec10"
            type="description">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: data.description }}></div>
          </LiveEdithTextHighLighter>
        </div>
        {/* Conditional Rendering for Image or Video */}
        {isImage(data?.image) ? (
          <img
            src={`/public/${data?.image}`}
            alt="HomeImage"
            loading="lazy"
            className="backgroundImage"
          />
        ) : isVideo(data?.image) ? (
          <video
            src={`/public/${data?.image}`}
            className="backgroundImage"
            autoPlay
            loop
            muted
          />
        ) : (
          <iframe
            className="backgroundImage"
            src={`https://www.youtube.com/embed/${data?.image}?autoplay=1&mute=1&loop=1&playlist=${data?.image}&controls=0&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              minWidth: "100%",
              objectFit: "cover",
              border: "none",
              aspectRatio: "16/9",
              height: "95vh",
              minHeight: "56vw",
            }}></iframe>
        )}
      </div>
    </Container>
  );
}
