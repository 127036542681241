import { useMessageContext } from "../../../context/Message.context";
import { SuccessBoxContainer, SuccessContainer } from "./Successbox.style";

export default function Successbox() {
  const { setSuccessShow, state } = useMessageContext();
  const handleShow = () => {
    setSuccessShow(false);
  };
  return (
    <SuccessContainer>
      <SuccessBoxContainer
        $theme={state.theme}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="svg_wrapper">
          <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.5 3.75C12.1875 3.75 3.75 12.1875 3.75 22.5C3.75 32.8125 12.1875 41.25 22.5 41.25C32.8125 41.25 41.25 32.8125 41.25 22.5C41.25 12.1875 32.8125 3.75 22.5 3.75ZM22.5 37.5C14.2313 37.5 7.5 30.7687 7.5 22.5C7.5 14.2313 14.2313 7.5 22.5 7.5C30.7687 7.5 37.5 14.2313 37.5 22.5C37.5 30.7687 30.7687 37.5 22.5 37.5Z"
              fill="white"
            />
            <path
              d="M20.3891 25.0362L28.2761 16.7621C28.6711 16.3477 29.3327 16.3488 29.7262 16.7646L30.3471 17.4206C30.713 17.8072 30.7119 18.4126 30.3446 18.7979L20.3905 29.2406C19.9965 29.654 19.3369 29.654 18.9428 29.2406L14.6577 24.7452C14.2895 24.3589 14.2895 23.7515 14.6577 23.3652L15.2751 22.7176C15.6686 22.3047 16.3271 22.3041 16.7214 22.7162L18.9428 25.0376C19.3371 25.4496 19.9956 25.449 20.3891 25.0362Z"
              fill="white"
            />
          </svg>
        </div>
        <p className="try_again_message"></p>
        <p className="message">{state.Message}</p>
        <button className="close_button" onClick={handleShow}>
          Okay, close this
        </button>
      </SuccessBoxContainer>
    </SuccessContainer>
  );
}
