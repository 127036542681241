import React, { useState } from 'react';
import './SeoMannagerHeader.css';

export default function SeoMannagerHeader({ onAddSeo }) {
  const [search, setSearch] = useState('idle');
  const [editor, setEditor] = useState('idle');

  const handleSearch = (event) => {
    event.stopPropagation();
    if (search === 'idle') {
      setSearch('search');
    } else {
      setSearch('idle');
    }
  };

  return (
    <nav className="d-flex justify-content-between align-items-center" onClick={() => setSearch('idle')}>
      <p className="header">SEO Manager</p>
      <div className="blogs_actions d-flex gap-5">
        <div className="create d-flex align-items-center gap-1" onClick={() => setEditor('edit')}>
          <div className="wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M6.25033 22.9168C5.67741 22.9168 5.18678 22.7127 4.77845 22.3043C4.37012 21.896 4.1663 21.4057 4.16699 20.8335V17.7085H6.25033V20.8335H18.7503V17.7085H20.8337V20.8335C20.8337 21.4064 20.6295 21.897 20.2212 22.3054C19.8128 22.7137 19.3225 22.9175 18.7503 22.9168H6.25033ZM4.16699 11.4585V4.16683C4.16699 3.59391 4.37116 3.10329 4.77949 2.69496C5.18783 2.28662 5.6781 2.0828 6.25033 2.0835H14.5837L20.8337 8.3335V11.4585H18.7503V9.37516H13.542V4.16683H6.25033V11.4585H4.16699ZM1.04199 15.6252V13.5418H23.9587V15.6252H1.04199Z"
                fill="#FAFAFC"
              />
            </svg>
          </div>
          <p className="sub_texts">Crawl now</p>
        </div>
        <div className="search d-flex align-items-center gap-1" onClick={handleSearch}>
          {search === 'search' && (
            <input
              type="text"
              placeholder="SEARCH"
              className="search_input"
              onClick={(event) => event.stopPropagation()}
            />
          )}
          <div className="wrapper" onClick={(event) => event.stopPropagation()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M16.1454 14.5834H15.3225L15.0308 14.3021C15.6818 13.5458 16.1576 12.655 16.4241 11.6934C16.6906 10.7318 16.7413 9.72311 16.5725 8.73962C16.0829 5.84379 13.6662 3.53128 10.7495 3.17712C9.72415 3.04739 8.68266 3.15396 7.70478 3.48867C6.7269 3.82337 5.83855 4.37735 5.1077 5.10819C4.37686 5.83904 3.82289 6.72739 3.48818 7.70527C3.15348 8.68315 3.04691 9.72463 3.17663 10.75C3.5308 13.6667 5.8433 16.0834 8.73913 16.573C9.72262 16.7418 10.7313 16.6911 11.6929 16.4246C12.6545 16.158 13.5453 15.6823 14.3016 15.0313L14.5829 15.323V16.1459L19.01 20.573C19.437 21 20.135 21 20.562 20.573C20.9891 20.1459 20.9891 19.448 20.562 19.0209L16.1454 14.5834ZM9.89538 14.5834C7.30163 14.5834 5.20788 12.4896 5.20788 9.89587C5.20788 7.30212 7.30163 5.20837 9.89538 5.20837C12.4891 5.20837 14.5829 7.30212 14.5829 9.89587C14.5829 12.4896 12.4891 14.5834 9.89538 14.5834Z"
                fill="#FAFAFC"
              />
            </svg>
          </div>
          {search === 'idle' && <p className="sub_texts">SEARCH</p>}
        </div>
      </div>
      <button className="add-seo-btn" onClick={onAddSeo}>Add SEO</button>
    </nav>
  );
}
