/** @format */

//import './SellerLeadeInfo.css';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { ConvertTime } from '../../../../utils/timezoneconvertor';
import View from './Images/view.svg';
import Preview from './preview';

export default function OtherLeadInfo({ Data }) {
  const [data, setData] = useState(Data);
  const [selectedValue, setSelectedValue] = useState(Data.status);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState('close');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('token');
      let source = axios.CancelToken.source();
      let raw_data = {
        other_lead_id: Data._id,
        status: selectedValue, //['New', 'Interested now', 'Interested later', 'Call Later', 'Not Interested', 'Do not contact', 'Meeting', 'Closed']
      };
      try {
        await axios.post('/admin/update-other-lead-status', raw_data, {
          cancelToken: source.token,
          headers: {
            'x-access-token': token,
          },
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error.message);
        }
      } finally {
        setLoading(false);
        await OtherLeadInfoInfo();
      }
    };
    fetchData();
  }, [selectedValue]);

  const OtherLeadInfoInfo = async () => {
    const token = Cookies.get('token');
    try {
      const getData = await axios.get(`/admin/other-lead/${data._id}`, {
        headers: {
          'x-access-token': token,
        },
      });
      setData(getData.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLoading(true);
    setSelectedValue(event.target.value);
  };

  const handlePreview = () => {
    setPreview('idle');
  };
  if (data === null) {
    console.log(data)
    return <p>Something went wrong</p>
  }
  return (
    <>
      <div
        className="seller_leader_info"

      >
        <p onClick={() => navigate(`/dashboard/other-detail/${data._id}`)}>{data.name}</p>
        <p onClick={() => navigate(`/dashboard/other-detail/${data._id}`)}>{data.email}</p>
        <p onClick={() => navigate(`/dashboard/other-detail/${data._id}`)}>{data.phone ? data.phone : 'N/A'}</p>
        <p onClick={() => navigate(`/dashboard/other-detail/${data._id}`)}>
          {data.updatedAt === null
            ? ConvertTime(data.createdAt)
            : ConvertTime(data.updatedAt)}
        </p>
        <div className="wrapper">
          <img onClick={() => navigate(`/dashboard/other-detail/${data._id}`)}
            src={View}
            alt="view"
          />
        </div>
        <div className="seller_selector">
          {loading === false ? (
            <select
              name=""
              id=""
              onChange={handleOnChange}
              value={data.status}>
              <option value="New">New</option>
              <option value="Appointment fixed">Appointment fixed</option>
              <option value="Meeting done">Meeting done</option>
              <option value="Valuation">Valuation</option>
              <option value="staging">Staging</option>
              <option value="Legal issue">Legal issue</option>
              <option value="Listed">Listed</option>
              <option value="Terminated">Terminated</option>
              <option value="Closed">Closed</option>
            </select>
          ) : (
            <div className="loader"></div>
          )}
        </div>
      </div>
      {preview === 'idle' && (
        <div
          className="seller_preview_container"
          onClick={() => setPreview('close')}>
          <Preview
            Data={data}
            setPreview={setPreview}
          />
        </div>
      )}
    </>
  );
}
