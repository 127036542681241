import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FeaturePropertyContainer, PropertyCardSlider } from '../../utilComponent/FeaturePropertySlider/FeaturePropertySlider.style';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { useMessageContext } from '../../../context/Message.context';
interface ExclusiveListingTemp1Props {
  data: {
    sec8: {
      color: string;
    };
  };
}

interface ExclusiveCard {
  _id: string;
  title: string;
  price: string;
  description: string;
  images: string[];
}

export default function ExclusiveListingTemp1({ data }: ExclusiveListingTemp1Props) {
  const [Exclusive, setExclusive] = useState<ExclusiveCard[]>([]);
  const Navigation = useNavigate()
  const { setErrorShow, setMessage } = useMessageContext()
  const ExclusivesliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };
  const handleNavigation = (exclusiveId: string | number) => {
    if (exclusiveId) {
      Navigation(`../exclusiveview/?id=${exclusiveId}`)
    } else {
      setErrorShow(true);
      setMessage(`cant access exclusive listing please login for exclusive listing`);

    }
  }
  useEffect(() => {
    const FetchData = async () => {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];
      let config = {
        headers: {},
        params: {},
      };

      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }

      try {
        const fetchData = await axios.get("/api/get-agent-exclusivelisting", config);
        const fetchedData = fetchData.data.data;
        setExclusive(fetchedData);
      } catch (e) {
        console.error("Error fetching exclusive listings:", e);
      }
    };
    FetchData();
  }, []);

  return (
    <FeaturePropertyContainer>
      <div className="text-center">
        <Slider {...ExclusivesliderSettings} className='slider_container'>
          {Exclusive.map((card) => (
            <PropertyCardSlider key={card._id} onClick={() => handleNavigation(card._id)}>
              <img src={'/public/' + card.images[0]} className='image' alt={card.title} />
              <div className="property_details">
                <h6 className="heading" style={{ textAlign: 'left' }}>
                  EXCLUSIVE LISTING
                </h6>
                <h2 className="second_heading" dangerouslySetInnerHTML={{
                  __html: card?.title,
                }}></h2>
                <div className="property_info_container">
                  <h4 className='info_property'>Price</h4>
                  <h4 className='info_property' dangerouslySetInnerHTML={{
                    __html: card?.price,
                  }}></h4>
                </div>
              </div>
              <div className="redirect">LEARN MORE</div>
            </PropertyCardSlider>
          ))}
        </Slider>
      </div>
    </FeaturePropertyContainer>
  );
}

const NextArrow: React.FC<{ className?: string; style?: React.CSSProperties; onClick?: () => void }> = ({ className, style, onClick }) => {
  return (
    <div className={className} style={{ ...style, display: "block", right: "40px", zIndex: 1 }} onClick={onClick}>
      <MdArrowForwardIos size={30} color="white" />
    </div>
  );
};

const PrevArrow: React.FC<{ className?: string; style?: React.CSSProperties; onClick?: () => void }> = ({ className, style, onClick }) => {
  return (
    <div className={className} style={{ ...style, display: "block", left: "20px", zIndex: 1 }} onClick={onClick}>
      <MdOutlineArrowBackIos size={30} color="white" />
    </div>
  );
};
