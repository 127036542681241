import React from 'react'
import { Helmet } from 'react-helmet-async'

type HelmetSEOProps = {
  seoHomeData?: {
    description: string,
    keywords: string
  },
  profile?: {
    name: string
  },
  defaultDescription: string,
  defaultKeywords: string,
  image?: string | null
}

export const HelmetSEO = ({ seoHomeData, profile, defaultDescription, defaultKeywords, image = null }: HelmetSEOProps) => {
  return (
    <Helmet>
      <title>{profile?.name || 'Agent'}</title>
      <meta
        name="description"
        content={seoHomeData ? `${seoHomeData.description} | ${defaultDescription}` : defaultDescription}
      />
      <meta
        name="keywords"
        content={seoHomeData ? `${seoHomeData.keywords}, ${defaultKeywords}` : defaultKeywords}
      />
      <meta property='og:title' content={profile?.name || 'Agent'} />
      <meta property='og:description' content={seoHomeData ? `${seoHomeData.description} | ${defaultDescription}` : defaultDescription}
      />
      {image && <meta property='og:image' content={image} />}
      <meta property="og:type" content='website' />
      <meta property="og:site_name" content='Real Estate Experts' />
      <meta property="og:locale" content="en_CA" />
    </Helmet>
  )
}
