/** @format */

import styled from 'styled-components';

type TextProps = {
  $color: string;
};

export const Title = styled.div<TextProps>`
  color: ${(props) => props.$color};
  font-family: Tenor Sans, sans-serif;
  font-size: clamp(1.5rem, 3vw, 3rem);
  font-weight: 600;

  & p {
    color: ${(props) => props.color};
    font-family: Montserrat,sans-serif;
    font-size: clamp(1.5rem, 3vw, 3rem);
    font-weight: 600;
  }
`;

export const Description = styled.div<TextProps>`
  color: ${(props) => props.$color};
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
  & p {
    color: ${(props) => props.$color};
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
`;
