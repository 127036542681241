import axios from 'axios'
import React, { useState } from 'react'
import { Form } from 'react-router-dom'
import ConvertToCanadianNumber from '../../../../utils/New_construction_details/ConvertNumberToDollar.util'
import moment from 'moment'
import Cookies from 'js-cookie'
type ValuationProps = {
  min: number | null,
  max: number | null,
  average: number | null
}
export default function () {
  const [Valuation, setValuation] = useState<ValuationProps>({
    "average": null,
    "min": null,
    "max": null
  })
  const [Property, setProperty] = useState([])
  const [Loading, setLoading] = useState<boolean | null>(null)
  const [FormData, setFormData] = useState({
    address: "",
    address_short: "",
    name: "",
    email: "",
    phone: "",
    type: "",
    sell_frequency: undefined,
    basement: "No",
    another_house_buy_plan: undefined,
    from_price: undefined,
    to_price: undefined,
    bedrooms: "",
    bathrooms: "",
    square_foot: "",
    homeStyle: "",
    garage: "",
    extra_info: "No details available",
    zip_code: "",
    latitude: 0,
    longitude: 0,
    offer_type: "cash",
    valuation_property_finshed_basement_type: "",
    valuation_property_unit: undefined,
    city: "",
  })
  const SoldInDays = (listedDay, soldDay): number => {
    if (!listedDay || !soldDay) {
      return null;
    }
    let start = moment(listedDay);
    let end = moment(soldDay);

    return end.diff(start, 'days');
  };
  const onSubmit = async () => {
    try {
      setLoading(true)
      const url = 'https://valuation.santhoshmathew.com/api/v1/get-home-value-estimation-only-test'
      const { homeStyle, ...PayloadData } = FormData
      const responseData = await axios.get(url, { params: { ...PayloadData, style: homeStyle } })
      setValuation({
        "average": responseData.data.data.average,
        "min": responseData.data.data.min,
        "max": responseData.data.data.max
      })
      setProperty(responseData.data.data.properties.map((item) => ({
        id: item.id,
        img: item.photos,
        price: ConvertToCanadianNumber(item.sold_price),
        listed_price: ConvertToCanadianNumber(
          item.property_details?.['Lp_dol']
        ),
        sold_on: item?.sold_date?.split(' ')?.[0],
        sold_in: SoldInDays(
          item?.property_details?.Input_date,
          item?.property_details?.Unavail_dt
        ),
        title: `${item.address}`,
        text: item.realtor,
        bedrooms: item.bedrooms, bathrooms: item.bathrooms,
        garage: item.garage,
        city: item.city,
        sale_or_lease: 'sold',
        redirect: 'sold/soldView',

      })))
      await SubmitFormSellerLead()
    } catch (e) {
      throw new Error(e)
    } finally {
      setLoading(false)
    }
  }
  const SubmitFormSellerLead = async () => {
    try {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];
      let config = {};
      if (agentUrl.includes("agentshades")) {
        config = {
          headers: {
            "x-access-token": token,
          },
        };
      }
      await axios.post("/api/add-seller-lead", {
        name: FormData.name,
        phone: FormData.phone,
        email: FormData.email,
        address: FormData.address,
        message: `
       Property Name:${FormData.address} , Estimated Price:${ConvertToCanadianNumber(Valuation.average)} ,
       Property Details:Property Type:${FormData.type}, Bedrooms:${FormData.bedrooms},Bathrooms:${FormData.bathrooms},Basement:${FormData.basement},Garage:${FormData.garage}
        `,
        domain_name: agentUrl
      }, config);
    } catch (err) {
      throw new Error(err.message);
    }
  };
  const handleStateChagne = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  return {
    onSubmit,
    Property,
    Valuation,
    FormData,
    Loading,
    setFormData,
    handleStateChagne
  }
}

