/** @format */

import {
  AbouTeamInfo,
  AboutInfoContainer,
  Container,
} from './aboutInfol.style';
//images

import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';

type AboutInfoProp = {
  data: {
    sec2: Record<string, string>;
    sec3: Record<string, string>;
  };
};

export default function AboutInfo({ data }: AboutInfoProp) {
  return (
    <Container>
      {data.sec2.visible && (
        <AboutInfoContainer id="sec2">
          <div className="leftSection" style={{ height: '700px', padding: '20px' }} >
            <LiveEditImageHighLighter
              section="sec2"
              type="image">
              <img
                src={`/public/${data?.sec2?.image}`}
                alt="InfoImage"
                className="InfoImage"
              // style={{filter: 'brightness(50%)'}}
              />
            </LiveEditImageHighLighter>
          </div>
          <div className="rightSection">
            <h1>
              <LiveEdithTextHighLighter
                section="sec2"
                type="text">
                {data?.sec2.text}
              </LiveEdithTextHighLighter>
            </h1>

            <LiveEditImageHighLighter
              section="sec2"
              type="description">
              <div dangerouslySetInnerHTML={{ __html: data?.sec2.description }} className="mb-2" ></div>
            </LiveEditImageHighLighter>
            <LiveEditImageHighLighter
              section="sec2"
              type="description2">
              <div dangerouslySetInnerHTML={{ __html: data?.sec2.description2 }} className="mb-2" ></div>
            </LiveEditImageHighLighter>
          </div>
        </AboutInfoContainer>
      )}
      {data.sec3.visible && (
        <AbouTeamInfo id="sec3">
          <div className="leftSection">
            <div className="svg_wrapper">
              <svg
                width="235"
                height="235"
                viewBox="0 0 235 235"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1168_601)">
                  <path
                    d="M51.4062 88.125C61.5734 88.125 71.5123 91.1399 79.966 96.7885C88.4197 102.437 95.0086 110.466 98.8994 119.859C102.79 129.252 103.808 139.588 101.825 149.56C99.8412 159.532 94.9452 168.692 87.7559 175.881C80.5666 183.07 71.407 187.966 61.4351 189.95C51.4633 191.933 41.1272 190.915 31.7339 187.024C22.3407 183.134 14.3121 176.545 8.66351 168.091C3.01492 159.637 -3.60523e-06 149.698 -4.49408e-06 139.531L-0.235021 132.188C-0.235022 118.686 2.42431 105.317 7.59112 92.8429C12.7579 80.3691 20.331 69.0351 29.8781 59.4881C39.4251 49.9411 50.7591 42.368 63.2328 37.2011C75.7066 32.0343 89.0759 29.375 102.577 29.375L102.577 58.75C92.9293 58.7241 83.3717 60.6111 74.4573 64.3018C65.5429 67.9926 57.4486 73.414 50.6425 80.2525C47.9972 82.8928 45.5569 85.7308 43.3428 88.7419C46.0102 88.3227 48.7061 88.1116 51.4062 88.1103L51.4062 88.125ZM183.594 88.125C193.761 88.125 203.7 91.1399 212.154 96.7885C220.607 102.437 227.196 110.466 231.087 119.859C234.978 129.252 235.996 139.588 234.012 149.56C232.029 159.532 227.133 168.692 219.943 175.881C212.754 183.07 203.594 187.966 193.623 189.95C183.651 191.933 173.315 190.915 163.921 187.024C154.528 183.134 146.5 176.545 140.851 168.091C135.202 159.637 132.187 149.698 132.187 139.531L131.952 132.187C131.952 104.92 142.784 78.7692 162.066 59.4881C181.347 40.207 207.497 29.375 234.765 29.375L234.765 58.75C225.117 58.724 215.559 60.611 206.645 64.3018C197.73 67.9926 189.636 73.414 182.83 80.2525C180.185 82.8928 177.744 85.7308 175.53 88.7419C178.198 88.3227 180.894 88.1115 183.594 88.1103L183.594 88.125Z"
                    fill="white"
                    fill-opacity="0.5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1168_601">
                    <rect
                      width="235"
                      height="235"
                      fill="white"
                      transform="translate(235 235) rotate(180)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h1>
              <LiveEdithTextHighLighter
                section="sec3"
                type="text">
                {data?.sec3?.text}
              </LiveEdithTextHighLighter>
            </h1>
            <LiveEdithTextHighLighter
              section="sec3"
              type="description">
              <div dangerouslySetInnerHTML={{ __html: data.sec3.description }} className="sub_text mb-2"></div>
            </LiveEdithTextHighLighter>
            <LiveEdithTextHighLighter
              section="sec3"
              type="description2">
              <div dangerouslySetInnerHTML={{ __html: data.sec3.description2 }} className="sub_text"></div>
            </LiveEdithTextHighLighter>
          </div>
          <div className="rightSection">
            <LiveEditImageHighLighter
              section="sec3"
              type="image">
              <img
                src={`/public/${data?.sec3?.image}`}
                alt="TeamImage"
                className="teamImage"
              />
            </LiveEditImageHighLighter>
          </div>
        </AbouTeamInfo>
      )}
    </Container>
  );
}
