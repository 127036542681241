import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
import { useDashboardMlsContext } from '../../../../context/DashboardMlsListing.context';
import useFetchMlsProperty from '../../customhooks/useFetchMlsProperty.customhook';
import { SharePropertyMainContainer, FetchPropertyContainer } from './shareProperty.style'
import Cookies from 'js-cookie';

const ShareToTwitter = ({ text, url, hashtags, via }) => {
  // Dynamically create the Twitter share URL
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    text
  )}&url=${encodeURIComponent(url)}${hashtags ? `&hashtags=${hashtags}` : ""}${via ? `&via=${via}` : ""
    }`;

  return (
    <a
      href={twitterUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        padding: "10px 15px",
        backgroundColor: "#1DA1F2",
        color: "white",
        borderRadius: "5px",
        textDecoration: "none",
        display: "inline-block",
      }}
    >
      Share on Twitter
    </a>
  );
};
const FacebookShare = ({ shareUrl }) => {

  const shareText = "Check out this awesome website!";

  const handleShare = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}&quote=${encodeURIComponent("Property")}`;
    window.open(facebookShareUrl, "_blank", "width=600,height=400");
  };

  return (
    <div>
      <button onClick={handleShare}>Share on Facebook</button>
    </div>
  );
};
const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <div
      className="slider_button prev"
      onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.21974 9.53024C6.07913 9.38959 6.00015 9.19886 6.00015 8.99999C6.00015 8.80111 6.07913 8.61038 6.21974 8.46974L10.4625 4.22699C10.5317 4.15535 10.6144 4.09822 10.7059 4.05891C10.7974 4.0196 10.8959 3.99891 10.9954 3.99805C11.095 3.99718 11.1938 4.01616 11.286 4.05387C11.3781 4.09158 11.4619 4.14727 11.5323 4.21769C11.6027 4.28811 11.6584 4.37185 11.6961 4.46402C11.7338 4.55619 11.7528 4.65495 11.7519 4.75454C11.7511 4.85412 11.7304 4.95254 11.6911 5.04404C11.6518 5.13554 11.5946 5.2183 11.523 5.28749L7.81049 8.99999L11.523 12.7125C11.6596 12.8539 11.7352 13.0434 11.7335 13.24C11.7318 13.4367 11.6529 13.6248 11.5139 13.7639C11.3748 13.9029 11.1867 13.9818 10.99 13.9835C10.7934 13.9852 10.6039 13.9096 10.4625 13.773L6.21974 9.53024Z"
          fill="#FAFAFC"
        />
      </svg>
    </div>
  );
};

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <div
      className="slider_button next"
      onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none">
        <g clip-path="url(#clip0_958_1173)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.7803 8.46976C11.9209 8.61041 11.9999 8.80114 11.9999 9.00001C11.9999 9.19889 11.9209 9.38962 11.7803 9.53026L7.53751 13.773C7.46833 13.8446 7.38557 13.9018 7.29407 13.9411C7.20256 13.9804 7.10415 14.0011 7.00456 14.002C6.90498 14.0028 6.80622 13.9838 6.71405 13.9461C6.62188 13.9084 6.53814 13.8527 6.46772 13.7823C6.3973 13.7119 6.34161 13.6282 6.3039 13.536C6.26619 13.4438 6.24721 13.345 6.24808 13.2455C6.24894 13.1459 6.26963 13.0475 6.30894 12.956C6.34824 12.8645 6.40538 12.7817 6.47701 12.7125L10.1895 9.00001L6.47701 5.28751C6.34039 5.14606 6.2648 4.95661 6.26651 4.75996C6.26822 4.56331 6.34709 4.37521 6.48615 4.23615C6.6252 4.09709 6.81331 4.01822 7.00996 4.01651C7.20661 4.0148 7.39606 4.09039 7.53751 4.22701L11.7803 8.46976Z"
            fill="#FAFAFC"
          />
        </g>
        <defs>
          <clipPath id="clip0_958_1173">
            <rect
              width="18"
              height="18"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const CustomPagination = ({ totalSlides, activeSlide, goToSlide }) => {
  const handleSlideClick = (index) => {
    goToSlide(index);
  };

  const renderPaginationItems = () => {
    const items = [];
    for (let i = 0; i < totalSlides.length; i++) {
      const isActive = activeSlide === i ? 'active' : '';
      items.push(
        <a
          key={i}
          className={`pagination-item ${isActive}`}
          onClick={() => handleSlideClick(i)}>
          <img
            src={totalSlides[i]}
            alt="home"
          />
        </a>
      );
    }
    return items;
  };

  return <div className="peginate">{renderPaginationItems()}</div>;
};
export const ShareProperty = (props: {}) => {
  const sliderRef = useRef(null);
  const { propertyData, FetchProperty, setPropertyData } = useFetchMlsProperty();

  const [domain, setDomain] = useState<string | null>('defaultDomain');
  const {
    state: { formType, mlsNumber },
    setFormVisualState,
    setFormType,
    setMlsNumber,
  } = useDashboardMlsContext();

  const Crmsettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'peginate',
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
  };
  const handleMlsNumber = (e) => {
    setMlsNumber(e.target.value);
  };
  useEffect(() => {
    const getDomain = async () => {

      const token = Cookies.get('token');
      const config = {
        headers: {
          'x-access-token': token,
        },
      };

      try {
        const getDomain = await axios.get('/admin/get-agent-domain', config)
        const Domain = await getDomain.data.data
        setDomain(Domain)
      } catch (e) {
        throw new Error(e)
      }
    }
    getDomain()
  }, [])
  return (
    <SharePropertyMainContainer>
      <Helmet>
        {/* Twitter Card Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Post Title" />
        <meta name="twitter:description" content="A short description of your post." />
        <meta name="twitter:image" content="https://s3.us-west-1.wasabisys.com/redbuyers-main/properties/image-N11914567-1.jpg" />

        {/* Open Graph Metadata */}
        <meta property="og:title" content="Your Post Title" />
        <meta property="og:description" content="A short description of your post." />
        <meta property="og:image" content="https://yourwebsite.com/image.jpg" />
      </Helmet>
      <FetchPropertyContainer>
        <div
          className="crm_edit_container"
          onClick={(event) => event.stopPropagation()}>
          <div className="edit_header d-flex justify-content-between align-items-center">
            <p>
              Preview:-
              {propertyData.address}
            </p>
          </div>
          <div className="main_info_container d-flex justify-content-between gap-1">
            {/* slider container */}
            <div className="crm_container_slider">
              <Slider
                {...Crmsettings}
                ref={sliderRef}>
                {propertyData.photos.length ? (
                  propertyData.photos.map((home) => (
                    <div
                      className="slider_home"
                      key={home}>
                      <img
                        src={home}
                        alt="home"
                      />
                    </div>
                  ))
                ) : (
                  <div className="slider_home">
                    {/* <img */}
                    {/*   src={ImageNotFound} */}
                    {/*   alt="ImageNotFound" */}
                    {/* /> */}
                  </div>
                )}
              </Slider>
              <div className="custom_page_nevigation">
                <CustomPagination
                  totalSlides={propertyData.photos} // Replace with the actual total number of slides
                  activeSlide={0}
                  goToSlide={(index: Number) => sliderRef.current.slickGoTo(index)}
                />
                <div className="crm_image_add">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none">
                    <path
                      d="M13.75 25H8.125C6.225 25 4.60417 24.3458 3.2625 23.0375C1.92083 21.7208 1.25 20.1167 1.25 18.225C1.25 16.6 1.7375 15.15 2.7125 13.875C3.69583 12.6 4.97917 11.7875 6.5625 11.4375C7.0875 9.52083 8.12917 7.97083 9.6875 6.7875C11.2542 5.59583 13.025 5 15 5C17.4417 5 19.5083 5.85 21.2 7.55C22.9 9.24167 23.75 11.3083 23.75 13.75C25.1917 13.9167 26.3833 14.5417 27.325 15.625C28.275 16.6917 28.75 17.9417 28.75 19.375C28.75 20.9417 28.2042 22.2708 27.1125 23.3625C26.0208 24.4542 24.6917 25 23.125 25H16.25V16.0625L18.25 18L20 16.25L15 11.25L10 16.25L11.75 18L13.75 16.0625V25Z"
                      fill="#999999"
                    />
                  </svg>
                  <p>Upload More</p>
                </div>
              </div>
            </div>
            <div className="crm_information d-flex justify-content-between flex-column">
              <div className="mls_number">
                <p>MLS Number</p>
                <div className="mls_form">
                  <input
                    type="text"
                    className="crm_input"
                    value={mlsNumber}
                    onChange={(e) => handleMlsNumber(e)}
                  //disabled={formType === 'preview'}
                  />
                  {formType === 'add' && (
                    <button
                      className="fetch_property_button"
                      onClick={FetchProperty}>
                      Fetch Details
                    </button>
                  )}
                </div>
              </div>
              <div className="name">
                <p>name</p>
                <input
                  type="text"
                  className="crm_input"
                  value={propertyData.name}
                  disabled={true}
                />
              </div>
              <div className="address">
                <p>address</p>
                <input
                  type="text"
                  className="crm_input"
                  value={propertyData.address}
                  disabled={true}
                />
              </div>
              <div className="price">
                <p>price</p>
                <input
                  type="text"
                  className="crm_input"
                  value={propertyData.price}
                  disabled={true}
                />
              </div>
              <div className="crm_edit_home_info d-flex justify-content-between">
                <div className="beds ">
                  <p>beds</p>
                  <input
                    className="crm_input"
                    type="text"
                    value={propertyData.bed}
                    disabled={true}
                  />
                </div>
                <div className="baths">
                  <p>baths</p>
                  <input
                    className="crm_input"
                    type="text"
                    value={propertyData.bath}
                    disabled={true}
                  />
                </div>
                <div className="garages">
                  <p>Garages</p>
                  <input
                    className="crm_input"
                    type="text"
                    value={propertyData.garage}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="house_type">
                <p>House Type</p>
                <input
                  className="crm_input"
                  type="text"
                  value={propertyData.house_type}
                  disabled={true}
                />
              </div>
              <div className="square_footage">
                <p>Square Footage</p>
                <input
                  type="text"
                  className="crm_input"
                  value={propertyData.square_footage}
                  disabled={true}
                />
              </div>
            </div>

            <div className="crm_description">
              <p>description</p>
              <textarea
                className="crm_textarea"
                value={propertyData.description}
                disabled={true}></textarea>
            </div>
          </div>
        </div>

      </FetchPropertyContainer>
      {domain !== null &&
        <>
          <ShareToTwitter
            text={`https::/${domain}/blue2/base/idxlisting/idxView/${mlsNumber}`}
            url={domain}
            hashtags={"Property"}
            via={domain}
          />
          <FacebookShare shareUrl={`https::/${domain}/blue2/base/idxlisting/idxView/${mlsNumber}`} />
        </>
      }
    </SharePropertyMainContainer>
  )
}

export default ShareProperty
