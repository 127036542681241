/** @format */

import styled from 'styled-components';

export const MainContainer = styled.div`
  height: 75vh;

  .image_container_hero_section {
    height: 100%;
    position: relative;
    .heading {
      font-family: 'Syncopate';
      font-size: clamp(1rem, 2rem, 2vw);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

type FlexContainerProps = {
  $flexDirection?: string;
  $backgroundColor?: string;
  $color?: string;
};

type ColorProps = {
  text: string | null
  background: string | null
  buttonBackground: string | null
  buttonText: string | null
}

const colorSwitcher = (color: string, type: string): ColorProps => {
  let Colors: ColorProps = {
    text: null,
    background: null,
    buttonBackground: null,
    buttonText: null
  }
  if (color === "black") {
    Colors.text = "white"
    Colors.background = color
    Colors.buttonBackground = "white"
    Colors.buttonText = color
  } else {
    Colors.text = "black"
    Colors.background = color
    Colors.buttonBackground = "black"
    Colors.buttonText = color
  }
  return Colors[type]
}

export const FlexContainer = styled.div<FlexContainerProps>`
  background: ${({ $backgroundColor }) =>
    colorSwitcher($backgroundColor, "background")};
  color: ${({ $backgroundColor }) =>
    colorSwitcher($backgroundColor, "text")};

  h1, h2, h3, h4, h5, h6 {
    color: ${({ $backgroundColor }) =>
    colorSwitcher($backgroundColor, "text")};
  }

  display: flex;
  gap: 1rem;
  // margin: 3rem auto;
  flex-wrap: wrap;
  flex-direction: ${({ $flexDirection }) => $flexDirection || "row"};
  // padding: 1rem;

  .row {
    width: 100%;
    padding-block: 6rem;
  }

  .left_section {
    flex: 0.5;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right_section {
    flex: 0.5;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
type RidirectButtonType = {
  $backgroundColor: string
}

export const RidirectButton = styled.a<RidirectButtonType>`
     font-family: "Montserrat";
     color: ${({ $backgroundColor }) => colorSwitcher($backgroundColor, "buttonText")};
     background: ${({ $backgroundColor }) => colorSwitcher($backgroundColor, "buttonBackground")};
     padding:10px 20px;
     text-align:center;
     text-decoration:none;
     border-radius:5px;
     display:inline-block; 
     margin:10px;
`
