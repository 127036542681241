/** @format */


import { stat } from "fs/promises";
import { useEffect, useState } from "react";
import { useLiveEditorHighLighter } from "../../../../context/liveEditorHighighlighter.context";
import LiveEdithTextHighLighter from "../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component";
import {
  Container,
  MobileLinks,
  MobileSection,
  NavLink,
  NavLinkContainer,
} from "./navbar.style";

type NavbarProps = {
  data?: Record<string, any>;
};

export default function Navbar({ data }: NavbarProps) {
  const [open, setOpen] = useState(true);
  const { state } = useLiveEditorHighLighter()
  console.log(state.type.split('-')[0])
  const handleOpenClose = () => {
    setOpen((prev) => !prev);
  };

  const handleScrollToPropertyCities = () => {
    const propertyCitiesElement = document.getElementById("Property_cards");
    if (propertyCitiesElement) {
      propertyCitiesElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const DropDownCheck = () => {

  }

  return (
    <>
      <Container id="header">
        <NavLinkContainer>
          <NavLink to="../home">
            <LiveEdithTextHighLighter section="header" type="home">
              <div dangerouslySetInnerHTML={{ __html: data?.home }}></div>
            </LiveEdithTextHighLighter>
          </NavLink>
          <NavLink to="../about_us">
            <LiveEdithTextHighLighter section="header" type="about_us">
              <div dangerouslySetInnerHTML={{ __html: data?.about_us }}></div>
            </LiveEdithTextHighLighter>
          </NavLink>
          <div className="dropdown ">
            <a
              className="dropdown-toggle"
              href="#"
              id="sellers"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded={false}
            //  aria-expanded={state.type.split('-')[0] === 'seller_actions'}
            >
              <LiveEdithTextHighLighter section="header" type="sellers">
                <div dangerouslySetInnerHTML={{ __html: data?.sellers }}></div>
              </LiveEdithTextHighLighter>
            </a>
            <ul className={`dropdown-menu ${['seller', 'home_valuation', 'blog'].includes(state.type.split('-')[0]) ? 'show' : ''
              }`} aria-labelledby="sellers">
              <li>
                <NavLink to={"../sellerguide"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[0]?.key}-0`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[0]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../homevaluation"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[1]?.key}-1`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[1]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../blogs"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[2]?.key}-2`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[2]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="dropdown ">
            <a
              className="dropdown-toggle"
              href="#"
              id="buyers"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded={state.type.split('-')[0] === 'buyer_actions'}
            >
              <LiveEdithTextHighLighter section="header" type="buyers">
                <div dangerouslySetInnerHTML={{ __html: data?.buyers }}></div>
              </LiveEdithTextHighLighter>
            </a>
            <ul className={`dropdown-menu ${['buyer', 'mortage_calculator'].includes(state.type.split('-')[0]) ? 'show' : ''
              }`} aria-labelledby="buyers">
              <li>
                <NavLink to={"../buyerguide"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.buyer_actions[0]?.key}-0`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[0]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../mortagecalculator"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.buyer_actions[1]?.key}-1`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[1]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              id="browse_home"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded={state.type.split('-')[0] === 'browse_home_actions'}
            >
              <LiveEdithTextHighLighter section="header" type="browse_home">
                <div dangerouslySetInnerHTML={{ __html: data?.browse_home }}></div>
              </LiveEdithTextHighLighter>
            </a>
            <ul className={`dropdown-menu ${['exclusive', 'feature', 'idx', 'sold', 'builder', 'map'].includes(state.type.split('-')[0]) ? 'show' : ''
              }`} aria-labelledby="browse_home">
              <li>
                <NavLink to={"../exclusivecards"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[0]?.key}-0`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[0]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../featuredcards"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[1]?.key}-1`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[1]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="../idxlisting"
                  onClick={handleScrollToPropertyCities}
                >
                  <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[2]?.key}-2`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[2]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../soldlisting"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[3]?.key}-3`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[3]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../builderdeals"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[4]?.key}-4`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[4]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../map"}>
                  <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[5]?.key}-5`}>
                    <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[5]?.text }}></div>
                  </LiveEdithTextHighLighter>
                </NavLink>
              </li>
            </ul>
          </div>
          <NavLink to={"../contact_us"}>
            <LiveEdithTextHighLighter section="header" type="contact_us">
              <div dangerouslySetInnerHTML={{ __html: data?.contact_us }}></div>
            </LiveEdithTextHighLighter>
          </NavLink>
        </NavLinkContainer>
        <MobileSection isOpen={open}>
          {open ? (
            <div className="svg_wrapper" onClick={handleOpenClose}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3.99976H21V5.99976H3V3.99976ZM3 10.9998H21V12.9998H3V10.9998ZM3 17.9998H21V19.9998H3V17.9998Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : (
            <div className="svg_wrapper" onClick={handleOpenClose}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 7L18 18M7 18L18 7"
                  stroke="white"
                  stroke-width="2.08333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </MobileSection>
        <MobileLinks isOpen={open}>
          <NavLink to="../home">
            <div dangerouslySetInnerHTML={{ __html: data?.home }}></div>
          </NavLink>
          <NavLink to="../about_us">
            <div dangerouslySetInnerHTML={{ __html: data?.about_us }}></div>
          </NavLink>
          {/* <NavLink to="../idxlisting" onClick={handleScrollToPropertyCities}>
            Browse Homes
          </NavLink> */}
          {/* <NavLink to={"../map"}>Map</NavLink> */}
          {/* <NavLink to={"../contact_us"}>Contact us</NavLink> */}
          <div className="dropdown ">
            <a
              className="dropdown-toggle"
              href="#"
              id="sellers"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div dangerouslySetInnerHTML={{ __html: data?.sellers }}></div>
            </a>
            <ul className="dropdown-menu" aria-labelledby="sellers">
              <li>
                <NavLink to={"../sellerguide"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[0]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../homevaluation"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[1]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../blogs"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[2]?.text }}></div>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="dropdown ">
            <a
              className="dropdown-toggle"
              href="#"
              id="buyers"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div dangerouslySetInnerHTML={{ __html: data?.buyers }}></div>
            </a>
            <ul className="dropdown-menu" aria-labelledby="buyers">
              <li>
                <NavLink to={"../buyerguide"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[0]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../mortagecalculator"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[1]?.text }}></div>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              id="browse_home"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div dangerouslySetInnerHTML={{ __html: data?.browse_home }}></div>
            </a>
            <ul className="dropdown-menu" aria-labelledby="browse_home">
              <li>
                <NavLink to={"../exclusivecards"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[0]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../featuredcards"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[1]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="../idxlisting"
                  onClick={handleScrollToPropertyCities}
                >
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[2]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../soldlisting"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[3]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../builderdeals"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[4]?.text }}></div>
                </NavLink>
              </li>
              <li>
                <NavLink to={"../map"}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[5]?.text }}></div>
                </NavLink>
              </li>
            </ul>
          </div>
          <NavLink to={"../contact_us"}>
            <div dangerouslySetInnerHTML={{ __html: data?.contact_us }}></div>
          </NavLink>
        </MobileLinks>
      </Container>
    </>
  );
}
