/** @format */

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

type ContainerProp = {
  sec5color: string;
};

const slideInFromBottom = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Container = styled.div<ContainerProp>`
  font-family: 'Montserrat';
  width: min(100%, 120rem);
  margin: 4rem auto;
  .sec2bg {
    padding: 50px 0 1px;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    filter: brightness(0.9);
  }
  p {
    font-family: 'Cormorant Garamond';
  }
  h1 {
    color: ${(props) => props.theme.colors.text2};
    font-size: clamp(1.5rem, 3vw, 3rem);
    font-weight: 400;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-bottom: 1.5rem;
    animation: ${slideInFromBottom} 6s ease-out;
  }
  p {
    //color: ${(props) => props.theme.colors.text2};
    font-size: clamp(0.75rem, 1.2vw, 1.5rem);
    font-weight: 400;
    text-align: center;
    margin-bottom: 1.5rem;
    max-width: 800px;
    margin: auto;
  }
  .sub_heading {
    padding: 0 clamp(1rem, 5vw, 7rem);
    color: #000000;
  }
  .background_image_text {
    position: relative;
    height: 40vh;
    p {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: clamp(1.5rem, 3vw, 3rem);
      color: ${(props) => props.theme.colors.text2};
      text-transform: uppercase;
    }
    img {
      width: 100%;
      height: 100%;
      /* object-fit: ; */
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #d9d9d9;
      opacity: 0.5;
    }
  }
  .year {
    color: #626687;
  }

  .cities_container {
    padding: clamp(1rem, 4vw, 4rem);
    background-color: ${(props) => props.theme.colors.primary};
    .quote {
      position: relative;
      width: min(79rem, 95%);
      margin: 0 auto;
      min-height: 10rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-family: 'Libre Baskerville';
        font-size: clamp(1rem, 2vw, 2rem);
        font-style: italic;
        font-weight: 400;
        color: ${(props) => props.theme.colors.text};
        align-self: center;
        text-align: center;
      }
      .svg_wrapper {
        position: absolute;
        right: clamp(0%, 11vw, 10rem);
        top: 10%;
        right: 0;
        height: 100%;
        width: fit-content;
        text-align: right;
        z-index: 1;
        svg {
          /* min-width: 2rem;
          max-width: 15rem; */
          width: clamp(4em, 15vw, 20rem);
        }
      }
    }
  }

  .parallax-container {
    position: relative;
    height: 80vh;
    overflow: hidden;
  }

  .parallax-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    /* z-index: -1; */
    filter: brightness(0.4);
  }

  .parallax-content {
    position: relative;
    z-index: 1;
    padding: 2rem 1rem;
    text-align: center;
    color: white;
  }

  .team {
    max-height: 70vh;
    overflow: hidden;
    position: relative;
    background-color: #000;
  }

  .team_content {
    position: absolute;
    width: min(95%, 45rem);
    top: 25%;
    left: 2%;
    text-align: left;

    /* Text styling */
    h1 {
      color: ${(props) => props.sec5color};
      text-align: left;
      font-family: 'Montserrat';
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    }

    p {
      color: ${(props) => props.sec5color};
      text-align: left;
      font-family: 'Montserrat';
      padding-bottom: 10px;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    }

    /* Button styling */
    button {
      color: ${(props) => props.sec5color};
      border: 1px solid ${(props) => props.sec5color};
      background: transparent;
      padding: 0.75rem 1.5rem;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
  }

  /* Mobile responsiveness */
  @media screen and (max-width: 647px) {
    .team_content {
      position: static;
      h1 {
        text-transform: uppercase;
      }

      button {
        margin: 0 auto;
        text-align: center;
      }

      h1,
      p,
      button {
        text-align: center;
        color: ${(props) => props.theme.colors.text2};
        border-color: ${(props) => props.theme.colors.text2};
      }

      button {
        display: flex;
      }
      margin: 1rem;
    }

    .team {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .information {
    width: min(110rem, 95%);
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }

  @media (max-width: 1024px) {
    .information {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 769px) {
    .information {
      grid-template-columns: 1fr;
    }
  }

  .CardInfo {
    width: 100%;
    margin: 1.5rem auto;
    padding: 1.25rem;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
  }

  .CardInfo:hover {
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
    transform: translateY(-8px);
    transition: all 0.3s ease-in-out;
  }

  .CardInfo h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: bold;
    transition: font-size 0.3s ease;
  }

  .CardInfo:hover h3 {
    font-size: 1.8rem;
  }

  .CardInfo p {
    font-size: 1rem;
    color: #555;
    margin: 0;
    line-height: 1.6;
    transition: font-size 0.3s ease;
  }
`;

export const Cities = styled.div`
  font-family: 'Montserrat';
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 22rem));
  justify-content: space-between;
  gap: 1rem;

  position: relative;
  .svg_wrapper {
    display: block;
    margin-left: auto;
  }
`;

export const City = styled(Link)`
  font-family: 'Montserrat';
  position: relative;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 31.25rem;
  max-height: 22rem; */
  overflow: hidden;
  .city_image {
    width: 100%;
    overflow: hidden;
    img {
      /* max-width: 22.25rem; */
      max-height: 22rem;
      min-height: 22rem;
      min-width: 100%;
      object-fit: cover;
      filter: brightness(50%);
    }
  }

  .city_name {
    font-family: 'Montserrat';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(1.25rem, 2vw, 2.25rem);
    color: ${(props) => props.theme.colors.text};
    z-index: 1;
    text-transform: uppercase;
  }
`;

export const CustomArrowPrev = styled.div`
  position: absolute;
  top: 50%;
  left: -3%;
  cursor: pointer;
  transform: translateY(-50%);
  @media screen and (max-width: 933px) {
    top: 105%;
    left: 85%;
  }
  @media screen and (max-width: 753px) {
    left: 0;
  }
`;

export const CutomArrowNext = styled.div`
  position: absolute;
  top: 50%;
  right: -3%;
  cursor: pointer;
  transform: translateY(-50%);
  @media screen and (max-width: 933px) {
    top: 105%;
    right: 0;
  }
`;

export const SliderContainer = styled.div`
  font-family: 'Montserrat';
  margin-bottom: 3rem;
  .slider {
    padding: 1rem;
    .city_card {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
