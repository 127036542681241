/** @format */

import styled, { keyframes } from 'styled-components';
import { Container } from '../../Style/CommonStyle.t3';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
const slideInFromBottom = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
type ContainerProps = {
  $color?: string;
};



export const HeroContainer = styled(Container) <ContainerProps>`
  .HeroImage {
    // max-height: 85vh;
    overflow: hidden;
    position:relative;
    height: 95vh;
    min-height: 56vw;
    .backgroundImage {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      // aspect-ratio:16/9;
    }
     .overlayBackground{
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       background: rgba(0, 0, 0, 0.3); 
       z-index: 1; 
     }
   .overlay{
     position: absolute; 
     z-index: 2;
     width: 60%;
     padding: 2rem;
     top: 50%;
     transform: translateY(-50%); 
    // animation: ${slideInFromLeft} 3s ease-out;
      .Text {
         font-size: clamp(1.1rem, 3vw, 3rem);
         font-weight: 500;
         color: ${(props) => props.$color};
         text-transform: uppercase;
          font-family: 'Montserrat';

         margin: 0;
         animation: ${slideInFromLeft} 3s ease-out;
          animation-delay: 5s;
          animation-iteration-count: 2;
          animation-fill-mode: forwards;
    }
    
  }

    @media screen and (max-width: 776px) {
      .overlay{
        width: 100% !important;
      }
    }


  }
  .hero_information {
    margin-top: 2rem;
    .sub_header {
      font-family: 'Montserrat';
      font-size: 18px;
      font-size: clamp(1rem, 1.125vw, 1.125rem);
      text-align: center;
      color: ${({ theme: { colors } }) => colors.primary};
    }
    .header,
    .header p {
      font-family: Montserrat;
      font-size: 2rem;
      font-size: clamp(2rem, 4vw, 4rem);
      font-weight: 400;
      text-align: center;
      color: ${({ theme: { colors } }) => colors.primary};
      margin-top: -1rem;
    }
    .description,
    .description p {
      font-family: 'Cormorant Garamond';
      font-size: 18px;
      font-weight: 300;
      line-height: 1.3181rem;
      text-align: center;
      color: black;
      width: min(659px, 95%);
      margin: 0 auto;
    }
  }
`;
