/** @format */

import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import Citysearch from "../CitySearch/citysearch.component";
import {
  CardsContainer,
  IdxListingContainer,
  LoadingContainer,
  SearchFilterContainer,
} from "./idxListing.style";

import ReactPaginate from "react-paginate";
import { useFilterContext } from "../../../context/filter.context";
import FilterProperty from "../FilterBox/filter.component";

//import Gif
import NotFound from "../../../images/No result.gif";
import { getLocation } from "./api/geo_location";

import ConvertToCanadianNumber from "../../../utils/New_construction_details/ConvertNumberToDollar.util";
import PropertyCard, {
  PROPERTY_TYPE_CLASSES,
} from "../PropertyCard/PropertyCard.utilComponent";
import { useNavigate } from "react-router-dom";
import PropertyCard1 from "../PropertyCard1/PropertyCard1.utilComponent";

type propertyProps = {
  unique_id: any;
  photos: any[];
  list_price: number;
  address: string;
  county: string;
  zip_code: string;
  city: string;
  realtor: string;
  bedrooms: string;
  bathrooms: string;
  sale_or_lease: string;
  area: string;
  redirect: string;
  garage_spaces: string;
  additional_bathroom: string;
  updated_at: string;
};
type IdxListingType = {
  type: "template1" | "template3";
  cardType?: PROPERTY_TYPE_CLASSES;
};
export default function IdxListing({ type, cardType }: IdxListingType) {
  const [property, setproperty] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [loader, setloader] = useState("loading");
  const [resultCount, setResult] = useState(0);
  const [geoLocation, setGeoLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const Navigate = useNavigate();
  // const location = useLocation();
  // const Card = card_type[type]
  // const getQueryParams = () => {
  //   return new URLSearchParams(location.search);
  // };

  const { state } = useFilterContext();

  const {
    city,
    page,
    bathroom,
    bedroom,
    building_status,
    home_type,
    min_price,
    max_price,
    search,
    sortBy,
    sortDir,
    sale_or_lease,
    property_style,
    streetSearch,
    mlsNumber,
  } = state;

  const handleScrollUp = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const FetchLocation = async () => {
      try {
        const Data = await getLocation();
        if (search === "") {
          setGeoLocation((prev) => ({
            ...prev,
            latitude: Data[0],
            longitude: Data[1],
          }));
        } else {
          setGeoLocation((prev) => ({
            ...prev,
            latitude: null,
            longitude: null,
          }));
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };
    FetchLocation();
  }, [search]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function fetchData() {
      setloader("loading");
      setproperty([]);
      try {
        let resp: AxiosResponse<any, any>;
        let Mlsproperty = []
        if (mlsNumber === null) {
          resp = await axios.get(
            `https://valuation.santhoshmathew.com/api/v1/properties/residentialnew`,
            {
              cancelToken: source.token,
              params: {
                ...(min_price && { min_price }),
                ...(max_price && { max_price }),
                ...(home_type && { property_type: home_type }),
                ...(bedroom && { bedrooms: bedroom }),
                ...(bathroom && { bathrooms: bathroom }),
                ...(sortBy && { sortBy }),
                ...(sortDir && { sortDir }),
                ...(search && { city: search }),
                ...(currentPage && { page: currentPage }),
                ...(sale_or_lease && { sale_or_lease }),
                ...(property_style && { property_style }),
                ...(geoLocation.latitude && { latitude: geoLocation.latitude }),
                ...(geoLocation.longitude && {
                  longitude: geoLocation.longitude,
                })
              },
            },
          );
        } else {
          // resp = await axios.get(
          //   `https://valuation.santhoshmathew.com/api/v1/properties/residential/steet-search?street_search=${streetSearch}&latitude=43.694248&longitude=-79.812981`,
          // );
          const property = await axios.get(`https://valuation.santhoshmathew.com/api/v1/idx-property-details/${mlsNumber}`)
          const data = property.data.data
          Mlsproperty.push(data)
        }
        if (mlsNumber === null) {
          setLastPage(resp.data.meta.last_page);
          setResult(resp.data.meta.total);
          setproperty(
            resp.data.data.map((item: propertyProps) => ({
              id: item.unique_id,
              img: item.photos,
              price: ConvertToCanadianNumber(item.list_price),
              title: `${item.address} ${item.area} ${item.county}`,
              bedrooms:
                item?.bedrooms === null
                  ? 0
                  : Number(item?.bedrooms) > Number(item?.additional_bathroom)
                    ? Number(item?.bedrooms) - Number(item?.additional_bathroom)
                    : item?.bedrooms,
              bathrooms: item.bathrooms,
              garage: item.garage_spaces,
              city: item.city,
              sale_or_lease: item.sale_or_lease,
              // timeLabel: TimeLabel(item.updated_at),
              redirect: "idxView",
            })),
          );
        } else {
          setResult(1);
          console.log(Mlsproperty)
          setproperty(
            Mlsproperty.map((item: propertyProps) => ({
              id: item.unique_id,
              img: item.photos,
              price: ConvertToCanadianNumber(item.list_price),
              title: `${item.address} ${item.area} ${item.county}`,
              bedrooms:
                item?.bedrooms === null
                  ? 0
                  : Number(item?.bedrooms) > Number(item?.additional_bathroom)
                    ? Number(item?.bedrooms) - Number(item?.additional_bathroom)
                    : item?.bedrooms,
              bathrooms: item.bathrooms,
              garage: item.garage_spaces,
              city: item.city,
              sale_or_lease: item.sale_or_lease,
              // timeLabel: TimeLabel(item.updated_at),
              redirect: "idxView",
            })),
          );

        }
        setloader("completed");
        handleScrollUp();
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    fetchData();
    return () => {
      source.cancel("Api call got canceled by cleanup.");
    };
  }, [
    currentPage,
    city,
    page,
    bathroom,
    bedroom,
    building_status,
    home_type,
    min_price,
    max_price,
    sortBy,
    sortDir,
    sale_or_lease,
    search,
    property_style,
    geoLocation.latitude,
    geoLocation.longitude,
    mlsNumber
  ]);
  const handlePageChange = (pageIndex: { selected: number }) => {
    const UpdatedValue = pageIndex.selected + 1;
    setCurrentPage(UpdatedValue);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <SearchFilterContainer>
        <Citysearch types="idx" />
        <FilterProperty types="idx" totalCount={resultCount} />
        <button className="button_map" onClick={() => Navigate("../map")}>
          Map Search
        </button>
      </SearchFilterContainer>
      <IdxListingContainer>
        {loader === "loading" ? (
          <LoadingContainer>
            <div className="loader"></div>
          </LoadingContainer>
        ) : loader === "completed" && property.length !== 0 ? (
          <CardsContainer>
            {property.map((item) => (
              <PropertyCard1 PropertyData={item} />
            ))}
          </CardsContainer>
        ) : (
          <LoadingContainer>
            <img src={NotFound} alt="Not Found" />
          </LoadingContainer>
        )}
        {mlsNumber === null && < ReactPaginate
          containerClassName="peginate_class"
          pageClassName="page"
          onPageChange={handlePageChange}
          forcePage={currentPage - 1}
          pageCount={lastPage}
        />}
      </IdxListingContainer>
    </>
  );
}
