/** @format */

import './LeadCount.css';

import {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import LeadCountHeader from './LeadCountHeader';

export default function LeadCount() {
  const [buyerLeads, setBuyerLeads] = useState(0);
  const [sellerLeads, setSellerLeads] = useState(0);
  const [otherLeads, setOtherLeads] = useState(0); 
  const [sort, setSort] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [todayLead, setTodaysLead] = useState(null)
  const [lastMonthLead, setLastMonthLead] = useState(null)
  const navigate = useNavigate();


  const fetchBuyerLeads = async (searchTerm: string) => {
    const token = Cookies.get("token");
    try {
      const response = await axios.get("/admin/buyer-lead/", {
        headers: {
          "x-access-token": token,
        },
        params: {
          sortOrder: sort,
          search: searchTerm,
        },
      });
      setBuyerLeads(response.data.total || 0);
      console.log("Buyer Leads:", response.data.total);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const fetchSellerLeads = async (searchTerm: string) => {
    const token = Cookies.get("token");
    try {
      const response = await axios.get("/admin/seller-lead/", {
        headers: {
          "x-access-token": token,
        },
        params: {
          sortOrder: sort,
          search: searchTerm,
        },
      });
      setSellerLeads(response.data.total || 0);
      console.log("Seller Leads:", response.data.total);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const fetchOtherLeads = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.get("/admin/other-lead/", {
        headers: {
          "x-access-token": token,
        },
      });
      setOtherLeads(response.data.total || 0);
    } catch (err) {
      console.error("Error fetching other leads:", err.message);
    }
  };

  useEffect(() => {
    fetchBuyerLeads(searchTerm);
    fetchSellerLeads(searchTerm);
    fetchOtherLeads();
  }, [sort]);

  const handleSearch = () => {
    fetchBuyerLeads(searchTerm);
    fetchSellerLeads(searchTerm);
  };
  useEffect(() => {
    const FetchLastMonthLead = async () => {
      const token = Cookies.get("token")
      try {
        const FetchData = await axios.get('/admin/get-lastmonth-lead', {
          headers: {
            "x-access-token": token
          }
        })
        const Data = FetchData.data.data
        setLastMonthLead(Data)
      } catch (e) {
        throw new Error(e)
      }
    }
    FetchLastMonthLead()
  }, [])
  useEffect(() => {
    const FetchtodaysLead = async () => {
      const token = Cookies.get("token")
      try {
        const FetchData = await axios.get('/admin/get-todays-lead', {
          headers: {
            "x-access-token": token
          }
        })
        const Data = FetchData.data.data
        setTodaysLead(Data)
      } catch (e) {
        throw new Error(e)
      }
    }
    FetchtodaysLead()
  }, [])
  return (
    <div className="Container">
      <LeadCountHeader />
      <div className="tile-container">
        <div
          className="tile"
          onClick={() => navigate("/dashboard/buyers-lead")}
        >
          <svg
            className="icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
              clip-rule="evenodd"
            />
          </svg>

          <div className="tile-text">
            <p>{buyerLeads}</p>
            <h2>Buyer Leads </h2>
          </div>
        </div>
        <div
          className="tile"
          onClick={() => navigate("/dashboard/seller-lead")}
        >
          <svg
            className="icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M5.535 7.677c.313-.98.687-2.023.926-2.677H17.46c.253.63.646 1.64.977 2.61.166.487.312.953.416 1.347.11.42.148.675.148.779 0 .18-.032.355-.09.515-.06.161-.144.3-.243.412-.1.111-.21.192-.324.245a.809.809 0 0 1-.686 0 1.004 1.004 0 0 1-.324-.245c-.1-.112-.183-.25-.242-.412a1.473 1.473 0 0 1-.091-.515 1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.401 1.401 0 0 1 13 9.736a1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.4 1.4 0 0 1 9 9.74v-.008a1 1 0 0 0-2 .003v.008a1.504 1.504 0 0 1-.18.712 1.22 1.22 0 0 1-.146.209l-.007.007a1.01 1.01 0 0 1-.325.248.82.82 0 0 1-.316.08.973.973 0 0 1-.563-.256 1.224 1.224 0 0 1-.102-.103A1.518 1.518 0 0 1 5 9.724v-.006a2.543 2.543 0 0 1 .029-.207c.024-.132.06-.296.11-.49.098-.385.237-.85.395-1.344ZM4 12.112a3.521 3.521 0 0 1-1-2.376c0-.349.098-.8.202-1.208.112-.441.264-.95.428-1.46.327-1.024.715-2.104.958-2.767A1.985 1.985 0 0 1 6.456 3h11.01c.803 0 1.539.481 1.844 1.243.258.641.67 1.697 1.019 2.72a22.3 22.3 0 0 1 .457 1.487c.114.433.214.903.214 1.286 0 .412-.072.821-.214 1.207A3.288 3.288 0 0 1 20 12.16V19a2 2 0 0 1-2 2h-6a1 1 0 0 1-1-1v-4H8v4a1 1 0 0 1-1 1H6a2 2 0 0 1-2-2v-6.888ZM13 15a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Z"
              clip-rule="evenodd"
            />
          </svg>

          <div className="tile-text">
            <p>{sellerLeads}</p>
            <h2>Seller Leads </h2>
          </div>
        </div>
        <div
          className="tile"
          onClick={() => navigate("/dashboard/other-leads")}
        >
          <svg
            className="icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
              clip-rule="evenodd"
            />
          </svg>
          <div className="tile-text">
            <p>{otherLeads}</p>
            <h2>Other Leads </h2>
          </div>
        </div>

        <div className="tile">
          <svg
            className="icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm10 5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-8-5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm1.942 4a3 3 0 0 0-2.847 2.051l-.044.133-.004.012c-.042.126-.055.167-.042.195.006.013.02.023.038.039.032.025.08.064.146.155A1 1 0 0 0 6 17h6a1 1 0 0 0 .811-.415.713.713 0 0 1 .146-.155c.019-.016.031-.026.038-.04.014-.027 0-.068-.042-.194l-.004-.012-.044-.133A3 3 0 0 0 10.059 14H7.942Z"
              clip-rule="evenodd"
            />
          </svg>
          <div className="tile-text">
            <p>{buyerLeads + sellerLeads + otherLeads}</p>
            <h2>Total Leads</h2>
          </div>
        </div>
      </div>
      <div className='total-lead' >
        <div className='today-lead'>
          <h4>Todays Leads</h4>
          {todayLead?.sellerLeads?.map((data) => (
            <div className='leads' onClick={() => navigate(`/dashboard/seller-detail/${data._id}`)}>
              <p >Name: {data.name}</p>
              <p>Status: {data.status}</p>
              <p>Seller Lead</p>
            </div>
          ))}
          {todayLead?.buyerLeads?.map((data) => (
            <div className='leads' onClick={() => navigate(`/dashboard/buyer-detail/${data._id}`)}>
              <p >Name: {data.name}</p>
              <p>Status: {data.status}</p>
              <p>Buyer Lead</p>
            </div>
          ))}

        </div>

        <div className='lastmonth-lead'>
          <h4>Last Month Leads</h4>
          {lastMonthLead?.sellerLeads?.map((data) => (
            <div className='leads' onClick={() => navigate(`/dashboard/seller-detail/${data._id}`)}>
              <p >Name: {data.name}</p>
              <p>Status: {data.status}</p>
              <p>Seller Lead</p>
            </div>
          ))}
          {lastMonthLead?.buyerLeads?.map((data) => (
            <div className='leads' onClick={() => navigate(`/dashboard/buyer-detail/${data._id}`)}>
              <p >Name: {data.name}</p>
              <p>Status: {data.status}</p>
              <p>Buyer Lead</p>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
}
