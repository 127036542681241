import { styled } from "styled-components";
import { DashboardMainContainer } from "../../CommonStyle/dashboardMain.style";

export const SharePropertyMainContainer = styled(DashboardMainContainer)`
 height:100%;
 margin-top:0;
`

export const FetchPropertyContainer = styled.div`

`
