import axios from "axios";
import Cookies from "js-cookie";
import { useMessageContext } from "../../context/Message.context";
import { useEffect, useState } from "react";

type FormDataType = {
  name: string;
  phone: string;
  email: string;
  message: string;
  address: string;
  agentUrl: string;
};
export default function useContactUs() {
  const { setErrorShow, setMessage, setSuccessShow } = useMessageContext();

  const [formData, setFormData] = useState<FormDataType>({
    name: "",
    phone: "",
    email: "",
    message: "",
    address: "",
    agentUrl: "",
  });

  const handleFormData = (
    field: string,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | string
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (typeof e === "string") {
      setFormData((prev) => ({ ...prev, [field]: e }));
    } else {
      setFormData((prev) => ({ ...prev, [field]: e.target.value }));
    }
  };
  useEffect(() => {
    const agentUrl = window.location.href.split("/")[2];
    //const agentUrl = "pj0022qazwsxedc.agentshades.com";
    handleFormData("domain_name", agentUrl);
  }, []);
  const SubmitFormSellerLead = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];
      let config = {};
      if (agentUrl.includes("agentshades")) {
        config = {
          headers: {
            "x-access-token": token,
          },
        };
      }
      await axios.post("/api/add-other-lead", formData, config);
      setSuccessShow(true);
      setMessage(`Your request got successfully register`);
      setFormData((prev) => ({
        ...prev,
        name: "",
        phone: "",
        email: "",
        message: "",
        address: "",
        agentUrl: "",
      }));
    } catch (err) {
      setErrorShow(true);
      setMessage(`Could't add your contact request `);
      throw new Error(err.message);
    }
  };
  return {
    SubmitFormSellerLead,
    handleFormData,
    formData,
  };
}
