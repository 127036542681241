/** @format */

import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import '../BuyersLeads/BuyersLeads.css';
import OtherLeadHeader from './OtherLeadHeader';
import { Link, useNavigate } from 'react-router-dom';
import useConvertIntoExcel from '../../customhooks/useConvertIntoExcel.customhook';
import { ConvertTime } from '../../../../utils/timezoneconvertor';
import OtherLeadInfo from './OtherLeadInfo';
export default function OtherLead() {
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('dsc');
  const [searchTerm, setSearchTerm] = useState('');
  const COlumnsLength = [
    { wpx: 150 },
    { wpx: 400 },
    { wpx: 200 },
    { wpx: 600 },
    { wpx: 600 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
  ];
  const { exportToExcel } = useConvertIntoExcel(COlumnsLength);

  const handleAllData = () => {
    const filterData = data.map((data) => ({
      name: data.name,
      email: data.email,
      phone: data.phone ? data.phone : 'N/A',
      message: data.message,
      address: data.address,
      status: data.status,
      createdAtTime: ConvertTime(data.createdAt).split('-')[0],
      createdAtDate: ConvertTime(data.createdAt).split('-')[1],
      updatedAtTime: ConvertTime(data.updatedAt).split('-')[0],
      updatedAtDate: ConvertTime(data.updatedAt).split('-')[1],
    }));
    exportToExcel(filterData, 'sellerLeads');
  };
  //search
  const handleSearch = () => {
    fetchData(searchTerm);
  };

  const fetchData = async (searchTerm: string) => {
    const token = Cookies.get('token');
    try {
      const response = await axios.get('/admin/other-lead/', {
        headers: {
          'x-access-token': token,
        },
        params: {
          sortOrder: sort,
          search: searchTerm,
        },
      });
      setData(response.data.data);
      console.log('otherLeads:', response.data.data);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  useEffect(() => {
    fetchData(searchTerm);
  }, [sort]);

  return (
    <div className="Container">
      <OtherLeadHeader
        fetchData={fetchData}
        setSearchTerm={setSearchTerm}
        Search={handleSearch}
        setSort={setSort}
        downloadAll={handleAllData}
      />

      <div className="seller_container">
        <div className="seller_leader_header">
          <p className="heading bar">name</p>
          <p className="heading bar">email</p>
          {/* <p className="heading bar">address</p>
          <p className="heading bar">message</p> */}
          <p className="heading bar">contact</p>
          <p className="heading bar center">date&time</p>
          <p className="heading bar center">view</p>
          <p className="heading bar center">mark status</p>
        </div>
        <div className="seller_info_list">
          {data && data?.length !== 0 ? (
            data?.map((data) => (
              <OtherLeadInfo
                Data={data}
                key={data?._id}
              />
            ))
          ) : (
            <p style={{ color: 'white', padding: '10px' }}>
              No leads data yet.
            </p>
          )}
        </div>
      </div>
    </div>


  );
}
