import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const SeoListting = ({ seoData, editData, onSave, onClose }) => {
  const [title, setTitle] = useState(editData?.title || "");
  const [description, setDescription] = useState(editData?.description || "");
  const [keywords, setKeywords] = useState(editData?.keywords || "");
  const [url, setUrl] = useState(editData?.url || "");
  const [notification, setNotification] = useState("");

  const token = Cookies.get("token");

  const handleSave = async () => {
    if (!description || !keywords || !url) {
      setNotification("All fields are required");
      return;
    }
    const isDuplicate = seoData.some(
      (entry) => entry.url === url && (!editData || entry.id !== editData.id)
    );
    if (isDuplicate) {
      setNotification("This URL is already in use. Please choose a unique URL.");
      return;
    }

    const newData = { description, keywords, url };

    try {
      if (editData && editData._id) {
        await axios.put(
          `/admin/edit-seo-entry/${editData._id}`,
          newData,
          { headers: { "x-access-token": token } }
        );
      } else {
        await axios.post(
          "/admin/add-seo-entry",
          newData,
          { headers: { "x-access-token": token } }
        );
      }

      onSave(newData, !!editData);
      setNotification("SEO settings saved successfully!");
      setTimeout(() => onClose(), 1000);
    } catch (error) {
      console.error("Error saving SEO settings:", error);
      setNotification(error.response?.data?.message || "Error saving SEO settings");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">{editData ? "Edit SEO Entry" : "Add SEO Entry"}</h2>
        <div className="modal-form">
          {/* <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="input_box_seo"
          /> */}
          <label htmlFor="" style={{color:"white"}}>Description</label>
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="input_box_seo"
          />
          {/* <input
            type="text"
            placeholder="Keywords"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            className="input_box_seo"
          /> */}
          <label htmlFor="" style={{color:"white"}}>Keywords</label>

            <textarea
            placeholder="Keywords"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            className="input_box_seo"
          />
          <select
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="input_box_seo"
          >
            <option value="" disabled>Select URL</option>
            <option value="/home">/home</option>
            <option value="/about">/about</option>
            <option value="/contact">/contact</option>
            <option value="/buyer">/buyer</option>
            <option value="/seller">/seller</option>
          </select>
          <button className="save_button" onClick={handleSave}>
            Save SEO
          </button>
          {notification && <p className="notification">{notification}</p>}
        </div>
        <button className="close_button" onClick={onClose}>
          ×
        </button>
      </div>
    </div>
  );
};

export default SeoListting;