import { useState } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useMessageContext } from '../../context/Message.context';

export default function useBuyerLead(formData) {
  const [loader, setloader] = useState<boolean>(false);
  const { setErrorShow, setMessage, setSuccessShow } = useMessageContext()
  const handleBuyerLead = async (e) => {
    const token = Cookies.get('token');
    e.preventDefault();
    setloader(true)
    const agentUrl = window.location.href.split('/')[2];
    try {
      let config = {};
      if (agentUrl.includes('agentshades')) {
        config = {
          headers: {
            'x-access-token': token,
          },
        };
      }
      await axios.post('/api/add-buyer-lead', formData, config);
      //    setloader(false);
      setSuccessShow(true);
      setMessage(`Information received. We'll contact you soon to show the property.`)
    } catch (err) {
      //   setloader(false);
      console.error('Error scheduling appointment:', err);
      setErrorShow(true);
      setMessage("Something went wrong. Please try again.");
    } finally {
      setloader(false);
    }
  }
  return {
    handleBuyerLead,
    loader
  }
}
