import React from 'react'
import { isFacebookVideo, isImage, isVideo } from '../../../utils/Helper_functions/VideoAndImage'
import FacebookVideoEmbed from '../FbEmbedVideo/FbEmbedVideo.utilComponents'
type VideoPlayerProps = {
  data: string,
  aspectRatio?: string,
}
export default function VideoPlayer({ data, aspectRatio }: VideoPlayerProps) {
  console.log(data)
  console.log(isFacebookVideo(data))
  return (
    <>
      {isImage(data) ? (
        <img
          src={`/public/${data}`}
          alt="HomeImage"
          loading="lazy"
          className="animatedText2 ImageSection"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover"
          }}
        />
      ) : isVideo(data) ? (
        <video
          src={`/public/${data}`}
          className="ImageSection"
          controls
          muted={false}
          loop
          style={{ width: '100%', objectFit: 'cover' }}
        />) : isFacebookVideo(data) ? <FacebookVideoEmbed videoUrl={data} aspectRatio={aspectRatio} /> : (
          <iframe
            className=""
            src={`https://www.youtube.com/embed/${data}?autoplay=0&mute=0&loop=1&playlist=${data}&controls=1&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              width: '100%', objectFit: 'cover',
              border: 'none',
              aspectRatio: aspectRatio
            }}></iframe>
        )
      }
    </>
  )
}

