import React from "react";
import ExclusiveListingHeading from "./components/ExclusiveListingHeader/ExclusiveListingHeader";
import {
  ExclusiveMainContainer,
  ExclusiveFormContainer,
} from "./ExclusiveListings.style";
import { useDashboardExclusiveListing } from "../../../../context/DashboardExclusiveListing";
import { ExclusiveForm } from "./components/ExclusiveForm/ExclusiveForm";
import useExclusiveListing from "./useExclusive";
import Spinner from "../../../../utils/spinner/spinner.component";
import ExclusivePropertyCard from "./components/ExclusiveCard/ExclusiveCard";
import { ExclusivePriviewCard } from "./components/ExclusivePriview/ExclusivePriview";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const ExclusiveListings = () => {
  const {
    state: { ExclusiveListing, formVisualState, previewVisualState },
    setExclusiveListing,
  } = useDashboardExclusiveListing();
  const { loader, updateListingOrder } = useExclusiveListing();

  // Handle Drag End
  const handleDragEnd = async (result: any) => {
    const { source, destination } = result;

    // If dropped outside the list or no destination, return
    if (!destination) return;

    // Reorder the ExclusiveListing array
    const updatedListing = Array.from(ExclusiveListing);
    const [reorderedItem] = updatedListing.splice(source.index, 1);
    updatedListing.splice(destination.index, 0, reorderedItem);

    setExclusiveListing(updatedListing);

    try {
      await updateListingOrder(updatedListing);
    } catch (err) {
      console.error("Failed to update listing order:", err);
    }
  };

  return (
    <div className="Container">
      <ExclusiveListingHeading />
      <ExclusiveMainContainer>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="exclusive-listing" direction="horizontal">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={{
                height: 'fit-content',
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, 28.125rem)",
                gridTemplateRows: "repeat(auto-fit, 10.625rem)",
                gap: "0.625rem",
                justifyContent: "start",
              }}>
                {ExclusiveListing.length > 0 && !loader ? (
                  ExclusiveListing.map((data, index) => (
                    <Draggable key={data._id} draggableId={data._id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: "16px",
                            display: "flex",
                          }}
                        >
                          <ExclusivePropertyCard Data={data} />
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : loader ? (
                  <div className="center_box">
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    <p>No Exclusive listings are available</p>
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ExclusiveMainContainer>
      {formVisualState && (
        <ExclusiveFormContainer>
          <ExclusiveForm />
        </ExclusiveFormContainer>
      )}
      {previewVisualState && <ExclusivePriviewCard />}
    </div>
  );
};

export default ExclusiveListings;
