/** @format */

import { motion, useCycle } from "framer-motion";
import { useRef, useState } from "react";
import { useLiveEditorHighLighter } from "../../../../context/liveEditorHighighlighter.context";
import LiveEdithTextHighLighter from "../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component";
import MenuToggle from "./menuetoggle.component";
import { NavLink, NavbarContainer, Sidebar } from "./navbar.style";
import { useDimensions } from "./use-dimentions";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 85% 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(25px at 85% 30px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};
export default function NavbarT3({ data }) {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [isOpens, setIsOpen] = useState(false);
  const { state } = useLiveEditorHighLighter()
  const toggleDropdown = () => setIsOpen(!isOpens);
  return (
    <NavbarContainer>
      <div className="links_container">
        <NavLink to={"../home"} >
          <LiveEdithTextHighLighter section="header" type="home">
            <div dangerouslySetInnerHTML={{ __html: data?.home }}></div>
          </LiveEdithTextHighLighter>
        </NavLink>
        {/* <NavLink to={"../idxlisting"}>Properties</NavLink>*/}
        <NavLink to={"../about_us"}>
          <LiveEdithTextHighLighter section="header" type="about_us">
            <div dangerouslySetInnerHTML={{ __html: data?.about_us }}></div>
          </LiveEdithTextHighLighter>
        </NavLink>
        {/*<NavLink to={"../map"}>Map</NavLink>*/}
        <div className="dropdown ">
          <a
            className="dropdown-toggle" 
            href="#"
            id="sellers"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{fontSize:"1.2rem",fontFamily:"Montserrat"}}
          >
            <LiveEdithTextHighLighter section="header" type="sellers">
              <div dangerouslySetInnerHTML={{ __html: data?.sellers }}></div>
            </LiveEdithTextHighLighter>
          </a>
          <ul className={`dropdown-menu ${['seller', 'home_valuation', 'blog'].includes(state.type.split('-')[0]) ? 'show' : ''
            }`} aria-labelledby="sellers">
            <li>
              <NavLink to={"../sellerguide"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[0]?.key}-0`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[0]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to={"../homevaluation"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[1]?.key}-1`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[1]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to={"../blogs"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[2]?.key}-2`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[2]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="dropdown ">
          <a
            className="dropdown-toggle"
            href="#"
            id="buyers"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{fontSize:"1.2rem",fontFamily:"Montserrat"}}

          >
            <LiveEdithTextHighLighter section="header" type="buyers">
              <div dangerouslySetInnerHTML={{ __html: data?.buyers }}></div>
            </LiveEdithTextHighLighter>

          </a>
          <ul className={`dropdown-menu ${['buyer', 'mortage_calculator'].includes(state.type.split('-')[0]) ? 'show' : ''
            }`} aria-labelledby="buyers">
            <li>
              <NavLink to={"../buyerguide"}>

                <LiveEdithTextHighLighter section="header" type={`${data?.buyer_actions[0]?.key}-0`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[0]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to={"../mortagecalculator"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.buyer_actions[1]?.key}-1`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[1]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="dropdown">
          <a
            className="dropdown-toggle"
            href="#"
            id="browse_home"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{fontSize:"1.2rem",fontFamily:"Montserrat"}}

          >
            <LiveEdithTextHighLighter section="header" type="browse_home">
              <div dangerouslySetInnerHTML={{ __html: data?.browse_home }}></div>
            </LiveEdithTextHighLighter>
          </a>
          <ul className={`dropdown-menu ${['exclusive', 'feature', 'idx', 'sold', 'builder', 'map'].includes(state.type.split('-')[0]) ? 'show' : ''
            }`} aria-labelledby="browse_home">
            <li>
              <NavLink to={"../exclusivecards"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[0]?.key}-0`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[0]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to={"../featuredcards"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[1]?.key}-1`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[1]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to="../idxlisting">
                <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[2]?.key}-2`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[2]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to={"../soldlisting"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[3]?.key}-3`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[3]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to={"../builderdeals"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[4]?.key}-4`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[4]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
            <li>
              <NavLink to={"../map"}>
                <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[5]?.key}-5`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[5]?.text }}></div>
                </LiveEdithTextHighLighter>
              </NavLink>
            </li>
          </ul>
        </div>
        <NavLink to={"../contact_us"}>
          <LiveEdithTextHighLighter section="header" type="contact_us">
            <div dangerouslySetInnerHTML={{ __html: data?.contact_us }}></div>
          </LiveEdithTextHighLighter>
        </NavLink>
      </div>
      <Sidebar
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <motion.div className="background" variants={sidebar}>
          <motion.ul variants={variants}>
            <li><NavLink to={"../home"}>
              <div dangerouslySetInnerHTML={{ __html: data?.home }}></div>
            </NavLink></li>
            {/* <NavLink to={"../idxlisting"}>Properties</NavLink>*/}
            <li><NavLink to={"../about_us"}>
              <div dangerouslySetInnerHTML={{ __html: data?.about_us }}></div>
            </NavLink></li>
            {/*<NavLink to={"../map"}>Map</NavLink>*/}
            <li>
              <div className="dropdown ">
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="sellers"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div dangerouslySetInnerHTML={{ __html: data?.sellers }}></div>
                </a>
                <ul className="dropdown-menu" aria-labelledby="sellers">
                  <li>
                    <NavLink to={"../sellerguide"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[0]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"../homevaluation"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[1]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"../blogs"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[2]?.text }}></div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="dropdown ">
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="buyers"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div dangerouslySetInnerHTML={{ __html: data?.buyers }}></div>
                </a>
                <ul className="dropdown-menu" aria-labelledby="buyers">
                  <li>
                    <NavLink to={"../buyerguide"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[0]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"../mortagecalculator"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[1]?.text }}></div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="dropdown">
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="browse_home"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div dangerouslySetInnerHTML={{ __html: data?.browse_home }}></div>
                </a>
                <ul className="dropdown-menu" aria-labelledby="browse_home">
                  <li>
                    <NavLink to={"../exclusivecards"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[0]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"../featuredcards"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[1]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="../idxlisting">
                      <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[2]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"../soldlisting"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[3]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"../builderdeals"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[4]?.text }}></div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"../map"}>
                      <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[5]?.text }}></div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li><NavLink to={"../contact_us"}>
              <div dangerouslySetInnerHTML={{ __html: data?.contact_us }}></div>
            </NavLink></li>
          </motion.ul>
          <MenuToggle toggle={() => toggleOpen()} />
        </motion.div>
      </Sidebar>
    </NavbarContainer>
  );
}
