
import React, { useEffect, useRef } from "react";

type FacebookVideoEmbedProps = {
  videoUrl: string,
  wdith?: string,
  showText?: boolean
  aspectRatio: string
}

const FacebookVideoEmbed = ({
  videoUrl, width = '100%', showText = false, aspectRatio }) => {
  const fbRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (document.getElementById("facebook-jssdk")) {
        // Already loaded
        window?.FB?.XFBML.parse(fbRef.current);
        return;
      }

      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window?.FB?.XFBML.parse(fbRef.current);
      };
      document.body.appendChild(script);
    };

    loadFacebookSDK();
  }, []);

  return (
    <div ref={fbRef}>
      <div
        className="fb-video "
        data-href={videoUrl}
        // data-width={900}
        // data-height={1000}
        data-show-text={showText}
        data-autoplay={true}
        style={{
          aspectRatio: aspectRatio
        }}
      ></div>
    </div >
  );
};

export default FacebookVideoEmbed;

