import { Link } from "react-router-dom";
import { useLiveEditorHighLighter } from "../../../context/liveEditorHighighlighter.context";
import LiveEdithTextHighLighter from "../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component";
import "./Header.css";
interface NavbarProps {
  data: any
}
export default function Header({ data }: NavbarProps) {
  const { state } = useLiveEditorHighLighter()
  console.log(state.type)
  return (
    <div className="template1" style={{ zIndex: '1050' }}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          {/* <a className="navbar-brand" href="home" style={{color: color}}>
          <img src={"/public/"+logo} alt={""} width="30" height="30" />
        </a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  style={{
                    fontFamily: "Montserrat,sans-serif",
                    margin: "0rem 1rem",
                    textTransform: "uppercase",
                  }}
                  to={"../home"}
                >
                  <LiveEdithTextHighLighter section="header" type="home">
                    <div dangerouslySetInnerHTML={{ __html: data?.home }}></div>
                  </LiveEdithTextHighLighter>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"../about"}
                  style={{
                    fontFamily: "Montserrat,sans-serif",
                    margin: "0rem 1rem",
                    textTransform: "uppercase",
                  }}
                >
                  <LiveEdithTextHighLighter section="header" type="about_us">
                    <div dangerouslySetInnerHTML={{ __html: data?.about_us }}></div>
                  </LiveEdithTextHighLighter>
                </Link>
              </li>
              <li className="nav-item">
                <div className="dropdown ">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    id="sellers"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontFamily: "Montserrat,sans-serif",
                      margin: "0rem 1rem",
                      textTransform: "uppercase",
                    }}
                  >
                    <LiveEdithTextHighLighter section="header" type="sellers">
                      <div dangerouslySetInnerHTML={{ __html: data?.sellers }}></div>
                    </LiveEdithTextHighLighter>
                  </a>
                  <ul className={`dropdown-menu ${['seller', 'home_valuation', 'blog'].includes(state.type.split('-')[0]) ? 'show' : ''
                    }`} aria-labelledby="sellers">
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../sellerguide"}
                      >
                        <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[0]?.key}-0`}>
                          <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[0]?.text }}></div>
                        </LiveEdithTextHighLighter>
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../homevaluation"}
                      >
                        <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[1]?.key}-1`}>
                          <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[1]?.text }}></div>
                        </LiveEdithTextHighLighter>
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../blogs"}
                      >
                        <LiveEdithTextHighLighter section="header" type={`${data?.seller_actions[2]?.key}-2`}>
                          <div dangerouslySetInnerHTML={{ __html: data?.seller_actions[2]?.text }}></div>
                        </LiveEdithTextHighLighter>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown ">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    id="buyers"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontFamily: "Montserrat,sans-serif",
                      margin: "0rem 1rem",
                      textTransform: "uppercase",
                    }}
                  >
                    <LiveEdithTextHighLighter section="header" type="buyers">
                      <div dangerouslySetInnerHTML={{ __html: data?.buyers }}></div>
                    </LiveEdithTextHighLighter>
                  </a>
                  <ul className={`dropdown-menu ${['buyer', 'mortage_calculator'].includes(state.type.split('-')[0]) ? 'show' : ''
                    }`} aria-labelledby="buyers">
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../buyerguide"}
                      >
                        <LiveEdithTextHighLighter section="header" type={`${data?.buyer_actions[0]?.key}-0`}>
                          <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[0]?.text }}></div>
                        </LiveEdithTextHighLighter>
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../mortagecalculator"}
                      >
                        <LiveEdithTextHighLighter section="header" type={`${data?.buyer_actions[1]?.key}-1`}>
                          <div dangerouslySetInnerHTML={{ __html: data?.buyer_actions[1]?.text }}></div>
                        </LiveEdithTextHighLighter>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <div className="dropdown">
                <a
                  className="dropdown-toggle nav-link"
                  href="#"
                  id="browse_home"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontFamily: "Montserrat,sans-serif",
                    margin: "0rem 1rem",
                    textTransform: "uppercase",
                  }}
                >
                  <LiveEdithTextHighLighter section="header" type="browse_home">
                    <div dangerouslySetInnerHTML={{ __html: data?.browse_home }}></div>
                  </LiveEdithTextHighLighter>
                </a>
                <ul className={`dropdown-menu ${['exclusive', 'feature', 'idx', 'sold', 'builder', 'map'].includes(state.type.split('-')[0]) ? 'show' : ''
                  }`} aria-labelledby="browse_home">
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../exclusivecards"}
                    >
                      <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[0]?.key}-0`}>
                        <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[0]?.text }}></div>
                      </LiveEdithTextHighLighter>
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../featuredcards"}
                    >
                      <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[1]?.key}-1`}>
                        <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[1]?.text }}></div>
                      </LiveEdithTextHighLighter>
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to="../idxlisting"
                    >
                      <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[2]?.key}-2`}>
                        <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[2]?.text }}></div>
                      </LiveEdithTextHighLighter>
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../soldlisting"}
                    >
                      <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[3]?.key}-3`}>
                        <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[3]?.text }}></div>
                      </LiveEdithTextHighLighter>
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../builderdeals"}
                    >
                      <LiveEdithTextHighLighter section="header" type={`${data?.browse_home_actions[4]?.key}-4`}>
                        <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[4]?.text }}></div>
                      </LiveEdithTextHighLighter>
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../map"}
                    >
                      <LiveEdithTextHighLighter section="header" type="contact_us">
                        <div dangerouslySetInnerHTML={{ __html: data?.browse_home_actions[5]?.text }}></div>
                      </LiveEdithTextHighLighter>
                    </Link>
                  </li>
                </ul>
              </div>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"../contact"}
                  style={{
                    fontFamily: "Montserrat,sans-serif",
                    margin: "0rem 1rem",
                    textTransform: "uppercase",
                  }}
                >
                  <LiveEdithTextHighLighter section="header" type="contact_us">
                    <div dangerouslySetInnerHTML={{ __html: data?.contact_us }}></div>
                  </LiveEdithTextHighLighter>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
