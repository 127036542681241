/** @format */

import CrmListtingHeader from "./MlsListtingHeader/MlsListtingHeader";
import CrmDetailCard from "./CrmDetailCard/MlsDetailCard";
import { useDashboardMlsContext } from "../../../../../context/DashboardMlsListing.context";
import Spinner from "../../../../../utils/spinner/spinner.component";
import MlsForm from "./CrmEdit/mlsForm";
import { MainContainer, MlsFormContainer } from "./MlsListing.style";
import useListing from "./useListing";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import axios from "axios";
import Cookies from "js-cookie";

export default function CrmListing() {
  const { loader } = useListing();
  const {
    state: { property = [], formVisualState },
    setProperty,
  } = useDashboardMlsContext();

  const handleDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) return;

    const reorderedItems = Array.from(property);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    setProperty(reorderedItems);

    const updatedOrder = reorderedItems.map((item, index) => ({
      id: item._id || item.mlsNumber,
      order: index,
    }));
    try {
      const token = Cookies.get("token");
      await axios.put(
        "/admin/update-idx-order",
        { updatedOrder },
        { headers: { "x-access-token": token } }
      );
    } catch (error) {
      console.error("Failed to update order:", error);
    }
  }



  return (
    <div className="Container crmListting_container">
      {/* Header Component */}
      <CrmListtingHeader />

      {/* Main Content */}
      <MainContainer>
        {loader ? (
          <div className="center_box">
            <Spinner />
          </div>
        ) : property.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="mls-list" direction="horizontal" >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="droppable-container"
                  style={{
                    height: 'fit-content',
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, 28.125rem)",
                    gridTemplateRows: "repeat(auto-fit, 10.625rem)",
                    gap: "0.625rem",
                    justifyContent: "start",
                  }}
                >
                  {property.map((data, index) => (
                    <Draggable
                      key={data.id || data.mlsNumber}
                      draggableId={data.id || data.mlsNumber}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: "16px",
                            display: "flex",
                          }}
                        >
                          <CrmDetailCard Data={data} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="center_box">
            <p>No MLS listings available</p>
          </div>
        )}
      </MainContainer>

      {/* Conditional Form Rendering */}
      {formVisualState && (
        <MlsFormContainer>
          <MlsForm />
        </MlsFormContainer>
      )}
    </div>
  );
}
