import { ReactNode, createContext, useContext, useReducer, useEffect } from 'react';
import CryptoJS from "crypto-js";

type AdminProps = {
  isAdmin: boolean
}

export type StateProps = {
  Admin: AdminProps | null;
}

type ContextValue = {
  state: StateProps;
  setAdmin: (Admin: AdminProps) => void;
}

const initialState: StateProps = {
  Admin: {
    isAdmin: false
  }
}

type Action = { type: 'set_admin'; payload: { Admin: AdminProps } }

const AuthReducer = (state: StateProps, action: Action) => {
  switch (action.type) {
    case 'set_admin':
      return { ...state, Admin: action.payload.Admin }
  }
}

const AuthContext = createContext<ContextValue | undefined>(undefined)

interface AuthContextProviderProps {
  children: ReactNode;
}

const SECRET_KEY = "aGVsbG9fd29ybGRfZ2VuZXJhdGVfa2V5IQ=="

const encryptData = (data: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()
}

const decryptData = (encryptedData: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};


const initializer = () => {
  const savedState = localStorage.getItem('myAuthContext');
  if (savedState) {
    try {
      return decryptData(savedState);
    } catch (error) {
      console.error("Failed to decrypt localStorage data", error);
      return initialState;
    }
  }
  return initialState;
};

const AuthContextProvider = ({
  children
}: AuthContextProviderProps) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState, initializer);

  useEffect(() => {
    try {
      const encryptedState = encryptData(state);
      localStorage.setItem('myAuthContext', encryptedState)
    } catch (error) {
      console.error('Failed to encrypt and save state', error);
    }
  }, [state])

  const setAdmin = (Admin: AdminProps) => {
    dispatch({ type: 'set_admin', payload: { Admin } })
  }

  const contextValue: ContextValue = {
    state,
    setAdmin
  }
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

//customHook

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('AuthContext must be used in withing a AuthContext')
  }
  return context
}

export default AuthContextProvider
