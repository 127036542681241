import './BuyerDetail.css';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

import { ConvertTime } from '../../../../utils/timezoneconvertor';

type BuyerDetailData = {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  message?: [{
    msg: string,
    date: string,
    _id: string
  }];
  emailHistory?: [{
    recipient: string,
    subject: string,
    sentAt: string,
    _id: string,
  }];
  status?: string;
  createdAt?: string;
  note?: string;
  updatedAt?: string;
  stage?: string;
};

type Params = {
  id: string;
};

export default function BuyerDetail(): JSX.Element {
  const { id } = useParams<Params>();
  const [data, setData] = useState<BuyerDetailData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedStage, setSelectedStage] = useState<string>("");
  const [activeTab, setActiveTab] = useState('buyerEnquiry');



  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // Modal state and form data state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    buyer_lead_id: id
  });

  useEffect(() => {
    const fetchBuyerInfo = async () => {
      const token = Cookies.get("token");
      try {
        const response = await axios.get(`/admin/buyer-lead/${id}`, {
          headers: {
            "x-access-token": token || "",
          },
        });
        setData(response.data.data);
        setSelectedValue(response.data.data.status || "");
        setSelectedStage(response.data.data.stage || "");
      } catch (err) {
        setError("Failed to fetch buyer details. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBuyerInfo();
  }, [id]);

  const updateStage = async (stage: string) => {
    const token = Cookies.get("token");
    const raw_data = {
      buyer_lead_id: data,
      stage,
    };
    setLoading(true);
    try {
      await axios.post("/admin/update-buyer-lead-stage", raw_data, {
        headers: {
          "x-access-token": token || "",
        },
      });
      setData((prevData) => ({ ...prevData, stage }));
    } catch (error) {
      console.error("Error updating stage:", error);
      alert("Failed to update stage. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const updateStatus = async (status: string) => {
    const token = Cookies.get("token");
    const raw_data = {
      buyer_lead_id: data,
      status,
    };
    setLoading(true);
    try {
      await axios.post("/admin/update-buyer-lead-status", raw_data, {
        headers: {
          "x-access-token": token || "",
        },
      });
      setData((prevData) => ({ ...prevData, status }));
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Failed to update status. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    if (data) {
      setFormData({
        name: data.name || '',
        email: data.email || '',
        phone: data.phone || '',
        address: data.address || '',
        buyer_lead_id: id
      });
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle update action, such as an API call to update the buyer's details.
    const token = Cookies.get("token");
    try {
      await axios.put(`/admin/update-buyer-lead/`, formData, {
        headers: {
          "x-access-token": token || "",
        },
      });
      // After updating, refresh the buyer's data
      setData((prevData) => ({
        ...prevData,
        ...formData,
      }));
      closeModal(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error updating buyer info:", error);
      alert("Failed to update buyer details. Please try again.");
    }
  };
  const handleNoteSubmit = async () => {
    const token = Cookies.get("token")
    try {
      await axios.post('/admin/update-buyer-lead-note', {
        buyer_lead_id: id,
        note: data.note
      }, {
        headers: {
          "x-access-token": token
        }
      })
    } catch (e) {
      throw new Error(e)
    }
  }
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const { name, email, phone, address, message, status, createdAt, note, updatedAt, stage, emailHistory } =
    data || {};

  return (
    <div className="buyer">
      <div className="buyer-info">
        <div className="edit-icon" onClick={openModal}>
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
            />
          </svg>
        </div>
        <div className="buyer-profile">
          <svg
            className="buyer-icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h2 className="buyer-name">{name || "N/A"}</h2>

        <div className="buyer-email">
          <svg
            className="buyer-email-icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M2.038 5.61A2.01 2.01 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6c0-.12-.01-.238-.03-.352l-.866.65-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z" />
            <path d="M20.677 4.117A1.996 1.996 0 0 0 20 4H4c-.225 0-.44.037-.642.105l.758.607L12 10.742 19.9 4.7l.777-.583Z" />
          </svg>
          <p>{email || "N/A"}</p>
        </div>
        <div className="buyer-phone">
          <svg
            className="buyer-phone-icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z" />
          </svg>

          <p>{phone || "N/A"}</p>
        </div>
        <div className="buyer-address">
          <svg
            className="buyer-address-icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
              clip-rule="evenodd"
            />
          </svg>

          <p>{address || "N/A"}</p>
        </div>

        <div className="lead-status-dropdown">
          <label htmlFor="lead-status">Lead Status:</label>
          <select
            style={{ width: "18rem" }}
            id="lead-status"
            name="lead-status"
            value={selectedValue}
            onChange={(e) => {
              const newValue = e.target.value;
              setSelectedValue(newValue);
              updateStatus(newValue);
            }}
          >
            <option value="New">New</option>
            <option value="Interested now">Interested now</option>
            <option value="Interested late">Interested later</option>
            <option value="Call Later">Call Later</option>
            <option value="Not Interested">Not Interested</option>
            <option value="Do not contact">Do not contact</option>
            <option value="Meeting">Meeting</option>
            <option value="Closed">Closed</option>
          </select>
        </div>

        <div className="add-notes-buyer-container">
          <h3 className="add-notes-buyer-heading">Add Notes</h3>
          <textarea
            className="notes-textarea"
            placeholder="Enter your notes here..."
            value={data.note}
            onChange={(e) =>
              setData((prevData) => ({
                ...prevData,
                note: e.target.value,
              }))
            }
          ></textarea>
          <button className="save-notes-button" onClick={handleNoteSubmit} >
            Save Notes
          </button>
        </div>
        <div className='lead-stage-dropdown'>
          <label htmlFor="lead-stage">Lead Stage:</label>
          <select
            style={{ width: "18rem" }}
            id="lead-stage"
            name="lead-stage"
            value={selectedStage}
            onChange={(e) => {
              const stageValue = e.target.value;
              setSelectedStage(stageValue);
              updateStage(stageValue);
            }}
          >
            <option value="lead">lead</option>
            <option value="Future">Future</option>
            <option value="Engaged">Engaged</option>
            <option value="Hot">Hot</option>
            <option value="Past clients">Past clients</option>
            <option value="Trash">Trash</option>
          </select>
        </div>

      </div>



      {/* Edit Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Buyer Information</h2>
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  className='input-modal'
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Email:
                <input
                  className='input-modal'
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Phone:
                <input
                  className='input-modal'
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Address:
                <input
                  className='input-modal'
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </label>
              <button type="submit">Save Changes</button>
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

<div className='buyer-second-row'>    
  
  
  
<div>
      <div style={{ display: 'flex' }}>
        <button
          style={{
            padding: '1rem',
            backgroundColor: activeTab === 'buyerEnquiry' ? 'black' : '#f1f1f1',
            color: activeTab === 'buyerEnquiry' ? '#fff' : '#000',
            border: activeTab === 'buyerEnquiry' ? '1px solid #fffff':"1px solid black",
            borderRadius: '0.5rem',
            cursor: 'pointer',
            flex: 1,
            textAlign: 'center',
            fontWeight:"bold"
          }}
          onClick={() => handleTabClick('buyerEnquiry')}
        >
          Buyer Enquiry
        </button>
        <button
          style={{
            padding: '1rem',
            backgroundColor: activeTab === 'email' ? 'black' : '#f1f1f1',
            color: activeTab === 'email' ? '#fff' : '#000',
            border: activeTab === 'email' ? '1px solid #fffff':"1px solid black",
            borderRadius: '0.5rem',
            cursor: 'pointer',
            flex: 1,
            textAlign: 'center',
            fontWeight:"bold"
          }}
          onClick={() => handleTabClick('email')}
        >
          Email History
        </button>
      </div>

      {/* Buyer Enquiry Section */}
      {activeTab === 'buyerEnquiry' && (
        <div className="buyer-leads">
          {message && message.map((data) => (
            <div className="buyer-message mt-3" key={data.date}>
              <p><b>Date And Time :</b> {ConvertTime(data.date)}</p>
              <p style={{ fontWeight: "bold" }}>Message:</p>
              <p>{data?.msg || "N/A"}</p>
              <br />
            </div>
          ))}
          <p className='mt-3'><b>Updated </b>: {ConvertTime(updatedAt)}</p>
        </div>
      )}

      {/* Email Section */}
      {activeTab === 'email' && (
        <div className="buyer-leads-email">
          {emailHistory && emailHistory.map((data) => (
            <div className="buyer-message mt-3" key={data.sentAt}>
              <p><b>Date And Time :</b> {ConvertTime(data.sentAt)}</p>
              <p style={{ fontWeight: "bold" }}>Recipient: {data.recipient}</p>
              <p style={{ fontWeight: "bold" }}>Message:</p>
              <p>{data?.subject || "N/A"}</p>
              <br />
            </div>
          ))}
          <p className='mt-3'><b>Updated </b>: {ConvertTime(updatedAt)}</p>
        </div>
      )}
    </div>



      </div>

      

    </div>
  );
}
