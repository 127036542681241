import React, { useState } from 'react'
import { AgentEditForm, AgentMainContainer } from './AgentEdit.style'
import useAgentAdmin from '../../utilComponents/useAgentAdmin.admin';
import { handleValidationOfPassword } from '../../../Auth/auth.functions'
interface AgentData {
  _id: string;
  username: string;
  activetemplate: number | null;
  firstname: string;
  lastname: string;
  email: string;
  status: 'banned' | 'active';
  domain: string;
  phone: number;
  password: string;
}

export default function AgentEdit({ data, close }: { data: AgentData, close: (arg: boolean) => void }) {
  const [AgentData, setAgentData] = useState(data)
  const [EditAgentData, setEditAgentData] = useState(data)
  const { updateTheUser, banUser, activateUser, updateThePassword } = useAgentAdmin({ id: data._id })
  const handleFormChange = (e) => {
    setEditAgentData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const resetTheData = (e) => {
    e.preventDefault()
    setEditAgentData(AgentData)
  }
  const hendleUploadData = (e) => {
    e.preventDefault()
    updateTheUser(EditAgentData)
  }
  const handleActiveBanAgent = (e) => {
    e.preventDefault()
    if (EditAgentData.status === "banned") {
      banUser(EditAgentData)
    } else {
      activateUser(EditAgentData)
    }
  }
  const handlePasswordUpdate = (e) => {
    e.preventDefault()
    updateThePassword({ "newPassword": EditAgentData.password })
  }
  return (
    <AgentMainContainer>
      <div className='agent_edit_header'>
        <h4>Agent Profile</h4>
        <button type='button' className='agent_button' onClick={() => close(false)}>
          close
        </button>
      </div>
      <AgentEditForm onSubmit={hendleUploadData}>
        <div className='input_section_container'>
          <label htmlFor="firstname">First Name:</label>
          <input name='firstname' type="text" id="firstname" value={EditAgentData.firstname} onChange={handleFormChange} />
          <label htmlFor="lastname">Last Name:</label>
          <input name="lastname" type="text" id="lastname" value={EditAgentData.lastname} onChange={handleFormChange} />
        </div>
        <div className='input_section_container'>
          <label htmlFor="email">Email:</label>
          <input name="email" type="email" id="email" value={EditAgentData.email} onChange={handleFormChange} />
        </div>
        <div className='input_section_container'>
          <label htmlFor="phone">Phone:</label>
          <input name="phone" type="text" id="phone" value={EditAgentData.phone} onChange={handleFormChange} />
        </div>
        <div className='input_section_container'>
          <label htmlFor="domain">Domain:</label>
          <input name="domain" type="text" id="domain" value={EditAgentData.domain} onChange={handleFormChange} />
        </div>
        <div className='agent_action_button'>
          <button type='reset' className='agent_button' onClick={resetTheData} >
            Reset
          </button>
          <button type='submit' className='agent_button'>
            Update
          </button>
        </div>
      </AgentEditForm>
      <AgentEditForm onSubmit={handleActiveBanAgent}>
        <div>
          <h4>Active/Ban Agent </h4>
        </div>
        <div className='input_section_container'>
          <label htmlFor="status">Status:</label>
          <select name="status" id="status" value={EditAgentData.status} onChange={handleFormChange}  >
            <option value="active">Active</option>
            <option value="banned">Ban</option>
          </select>
        </div>
        <div className='agent_action_button'>
          <button type='reset' className='agent_button' onClick={resetTheData} >
            Reset
          </button>
          <button type='submit' className='agent_button'>
            Update Agent Status
          </button>
        </div>
      </AgentEditForm>
      <AgentEditForm onSubmit={handlePasswordUpdate} >
        <div>
          <h4>Agent Password </h4>
        </div>
        <div className='input_section_container'>
          <label htmlFor="password">Current Password: {AgentData.password} </label>
          <input name="password" type="text" id="password" onChange={handleFormChange} />
        </div>
        <div className='agent_action_button'>
          <button type='reset' className='agent_button' onClick={resetTheData} >
            Reset
          </button>
          <button type='submit' className='agent_button'>
            Update The Password
          </button>
        </div>
      </AgentEditForm>

    </AgentMainContainer>
  )
}

