import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMessageContext } from '../../../../context/Message.context';
//import { CoverImages } from '../../../../utilComponent/PropertyCard/helperFunctions';
//import './builderCard.css';
import { Container } from './bloglistcard.style';
export const BlogCardList = ({ BlogData }) => {
  const Navigate = useNavigate();
  const { setErrorShow, setMessage } = useMessageContext()
  const handleNavigation = () => {
    if (BlogData._id) {
      Navigate(`../blogs/blogview/?id=${BlogData._id}`);
    } else {
      setErrorShow(true);
      setMessage(`cant access blog view page in preview session`);
    }
  };
  return (
    <Container onClick={handleNavigation}>
      <img
        src={
          `/public/${BlogData.coverImage}`
        }
      />
      <div className='lean-card-content'>
        <h4 className='lp-h4'>{BlogData.title}</h4>
        <div className='item-footer'>
          <p className='mb-2' dangerouslySetInnerHTML={{ __html: BlogData.description }}>

          </p>
        </div>
      </div>
    </Container >
  );
};
export default BlogCardList;
