import { ReactNode, createContext, useContext, useReducer } from "react";

export type formType = "add" | "update" | null;

export type PropertyProps = {
  _id?: string;
  userId?: string;
  title: string;
  price: string;
  description: string;
  images: string[];
};

export type State = {
  previewProperty: PropertyProps | null;
  ExclusiveListing: PropertyProps[]; // Updated to hold an array of PropertyProps
  formVisualState: boolean;
  formType: formType;
  previewVisualState: boolean;
  previewId: string | null;
};

type ContextValue = {
  state: State;
  setExclusiveListing: (value: PropertyProps[]) => void; // Updated setter for ExclusiveListing
  setPreviewProperty: (value: PropertyProps) => void;
  setFormVisualState: (value: boolean) => void;
  setFormType: (value: formType) => void;
  setPreivewVisualState: (value: boolean) => void;
  setPreviewId: (value: string) => void;
};

const initialState: State = {
  previewProperty: null,
  ExclusiveListing: [],
  formVisualState: false,
  formType: null,
  previewVisualState: false,
  previewId: null,
};

type Action =
  | { type: "set_exclusiveListing"; payload: { value: PropertyProps[] } }
  | { type: "set_previewProperty"; payload: { value: PropertyProps } }
  | { type: "set_formVisualState"; payload: { value: boolean } }
  | { type: "set_formType"; payload: { value: formType } }
  | { type: "set_previewVisualState"; payload: { value: boolean } }
  | { type: "set_previewId"; payload: { value: string } };

const DashboardExclusiveReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set_exclusiveListing":
      return { ...state, ExclusiveListing: action.payload.value };
    case "set_previewProperty":
      return { ...state, previewProperty: action.payload.value };
    case "set_formVisualState":
      return { ...state, formVisualState: action.payload.value };
    case "set_formType":
      return { ...state, formType: action.payload.value };
    case "set_previewVisualState":
      return { ...state, previewVisualState: action.payload.value };
    case "set_previewId":
      return { ...state, previewId: action.payload.value };
    default:
      return state;
  }
};

const DashboardExclusiveListingContext = createContext<ContextValue | undefined>(
  undefined
);

interface DashboardContextProviderProps {
  children: ReactNode;
}

const DashboardExclusiveContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  const [state, dispatch] = useReducer(DashboardExclusiveReducer, initialState);

  const setExclusiveListing = (value: PropertyProps[]) => {
    dispatch({ type: "set_exclusiveListing", payload: { value } });
  };

  const setPreviewProperty = (value: PropertyProps) => {
    dispatch({ type: "set_previewProperty", payload: { value } });
  };

  const setFormVisualState = (value: boolean) => {
    dispatch({ type: "set_formVisualState", payload: { value } });
  };

  const setFormType = (value: formType) => {
    dispatch({ type: "set_formType", payload: { value } });
  };

  const setPreivewVisualState = (value: boolean) => {
    dispatch({ type: "set_previewVisualState", payload: { value } });
  };

  const setPreviewId = (value: string) => {
    dispatch({ type: "set_previewId", payload: { value } });
  };

  const contextValue: ContextValue = {
    state,
    setExclusiveListing,
    setPreviewProperty,
    setFormVisualState,
    setFormType,
    setPreviewId,
    setPreivewVisualState,
  };

  return (
    <DashboardExclusiveListingContext.Provider value={contextValue}>
      {children}
    </DashboardExclusiveListingContext.Provider>
  );
};

export function useDashboardExclusiveListing() {
  const context = useContext(DashboardExclusiveListingContext);
  if (!context) {
    throw new Error(
      "DashboardExclusiveListingContext must be used within a DashboardExclusiveContextProvider"
    );
  }
  return context;
}

export default DashboardExclusiveContextProvider;