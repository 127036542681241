export const isImage = (filename: string | undefined) => {
  if (!filename) return false;
  return /\.(jpeg|jpg|png|gif)$/i.test(filename);
};

export const isVideo = (filename: string | undefined) => {
  if (!filename) return false;
  return /\.(mp4|mkv)$/i.test(filename);
};

export const isFacebookVideo = (url: string | undefined) => {
  const facebookRegex = /^(https?:\/\/)?(www\.)?(facebook\.com|fb\.com)(\/.*)?$/i;
  return facebookRegex.test(url);
}
