/** @format */

import './Template1pg2.css';

import {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useMessageContext } from '../../context/Message.context';
import LiveEdithTextHighLighter
  from '../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter
  from '../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import GetTemplateData from '../../utils/apis/GetTemplateData.api';
import { HelmetSEO } from '../../utils/HelmetSEO/HelmetSEO.utilcomponent';
import SiteBuilder from '../SiteBuilder/SiteBuilder';
//component
import OurTeam from '../Template2/components/ourteam/ourteam.t2';
import {
  Description,
  Title,
} from './aboutus.style';
import Footer from './Footer/Footer';
import Header from './Header/Header';

interface Seolisting {
  _id: string;
  title: string;
  keywords: string;
  description: string;
  url: string;
}

export default function Template1PG2({ templateData }) {
  const [data, setData] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [Seo, setSeo] = useState<Seolisting[]>([]);
  const [seoHomeData, setSeoHomeData] = useState<Seolisting | null>(null);
  const location = useLocation();
  const { preview } = useParams();
  const token = Cookies.get("token");

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const { setTheme } = useMessageContext();
  const params = getQueryParams();

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`../idxlisting/`);
  };

  useEffect(() => {
    const getFooter = async () => {
      const footerData = await GetTemplateData(preview, 1, 1);
      if (footerData) {
        setFooterData(footerData);
      }
    };
    getFooter();
  }, []);

  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      const agentUrl = window.location.href.split("/")[2];
      let config = {
        headers: {},
        params: {},
      };
      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }
      try {
        const profileData = await axios.get("/api/get-agent-profile", config);
        setProfile(profileData.data.data);
      } catch (e) {
        throw new Error(e);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const FetchSeo = async () => {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];
      let config = {
        headers: {},
        params: {},
      };

      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }

      try {
        const fetchData = await axios.get("/api/get-all-seo-entries", config);
        const fetchedData = fetchData.data.data;
        const homeSeoEntry = fetchedData.find((entry) =>
          entry.url.includes("about")
        );
        setSeoHomeData(homeSeoEntry || null);
      } catch (e) {
        console.error("Error fetching seo listings:", e);
      }
    };

    FetchSeo();
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, 1, 2);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            warning_message: {
              fontFamily: "Syncopate",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "20.82px",
            },
            warning_text: {
              fontFamily: "Garamond",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "22.32px",
              textAlign: "center",
            },
            button: {
              fontFamily: "Syncopate",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "18.74px",
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, []);

  if (!data)
    return (
      <div id="loadingScreen" className="loading-screen">
        <div className="loader"></div>
      </div>
    );

  const defaultDescription = `Detailed REALTOR® profile for ${profile?.name || "Agent"
    } , real estate agent at ROYAL STAR REALTY INC. in BRAMPTON, Ontario.`;
  const defaultKeywords =
    "real estate listings, homes for sale, homes for rent, property listings";

  const siteBuilder = params.get("siteBuilder");

  const customStyles = `
        :root {
            --primary: ${data.styles[0].css.primary};
            --secondary: ${data.styles[0].css.secondary};
        }
    `;
  const section1 = (
    <div
      className="sec1"
      id="sec1"
      style={{
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${"/public/" + data.sec1.image
          }) `,
        width: "100%",
        height: "95vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        backgroundPosition: "center",
      }}
    >
      <div className="info_container">
        <LiveEdithTextHighLighter section="sec1" type="text">
          <Title
            $color={data.sec1.color}
            style={{fontFamily:"Syncopate"}}
            dangerouslySetInnerHTML={{ __html: data.sec1.text }}
          ></Title>
        </LiveEdithTextHighLighter>
        {/* </h2> */}
        <LiveEdithTextHighLighter section="sec1" type="description">
          <Description
            $color={data.sec1.color}
            dangerouslySetInnerHTML={{
              __html: data.sec1.description,
            }}
          ></Description>
        </LiveEdithTextHighLighter>
        <button className="hero_button1" onClick={() => handleRedirect()}>
          Search listings
          <svg
            width="10"
            height="20"
            viewBox="0 0 10 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.85092 0.586716C1.54312 0.586109 1.24482 0.693977 1.00782 0.891594C0.874422 1.00287 0.764157 1.13954 0.683335 1.29377C0.602513 1.44799 0.552722 1.61674 0.536814 1.79036C0.520906 1.96398 0.539193 2.13905 0.590628 2.30555C0.642064 2.47204 0.725635 2.62669 0.836559 2.76064L6.7383 9.86565L1.04734 16.9839C0.937909 17.1195 0.856192 17.2755 0.806881 17.443C0.75757 17.6105 0.741638 17.7861 0.760001 17.9598C0.778364 18.1335 0.830659 18.3018 0.913881 18.4551C0.997103 18.6084 1.10961 18.7436 1.24494 18.853C1.38124 18.9736 1.54086 19.0647 1.71378 19.1203C1.8867 19.176 2.06918 19.1951 2.24978 19.1764C2.43038 19.1577 2.6052 19.1017 2.76326 19.0118C2.92131 18.922 3.0592 18.8002 3.16827 18.6541L9.53109 10.7008C9.72484 10.4636 9.83077 10.1661 9.83077 9.85903C9.83077 9.55199 9.72484 9.25448 9.53109 9.01729L2.94432 1.06392C2.81217 0.903502 2.6443 0.776693 2.45432 0.693781C2.26435 0.610868 2.05762 0.574188 1.85092 0.586716Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );

  const section2 = (
    <div className="sec2pg2" id="sec2">
      <div className="row">
        <div className="col-md-6">
          <LiveEditImageHighLighter section="sec2" type="img">
            <img src={"/public/" + data.sec2.img} alt="" />
          </LiveEditImageHighLighter>
        </div>
        <div className="col-md-6">
          <div style={{}}>
            <h2 style={{ color: data.sec2.color, fontFamily: "Syncopate" }}>
              <LiveEdithTextHighLighter section="sec2" type="text">
                {data.sec2.text}
              </LiveEdithTextHighLighter>
            </h2>
            <div className="pb-3">
              {data.sec2.description.map((content, index) => (
                <p style={{ color: data.sec2.color, fontFamily: "Montserrat,sans-serif" }}>
                  <LiveEdithTextHighLighter
                    section="sec2"
                    type={`description${index}`}
                  >
                    {content}
                  </LiveEdithTextHighLighter>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const section3 = (
    <div
      className="sec3pg2"
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
      id="sec3"
    >
      <div className="container">
        <h3
          style={{
            color: data.sec3.color,
            fontFamily: "Montserrat,sans-serif",
            textAlign: "center",
          }}
        >
          <LiveEdithTextHighLighter section="sec3" type="text">
            {data.sec3.text}
          </LiveEdithTextHighLighter>
        </h3>
      </div>
    </div>
  );

  const section4 = (
    <div
      id="sec4"
      style={{
        position: "relative",
        minHeight: "100vh",
        backgroundImage: `url('/public/${data.sec4.image}')`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="section4_about_us"
    >
      {/* Content Section */}
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          width: "min(970px, 95%)",
          marginLeft: "auto",
          marginRight: "0",
          top: "20%",
          left: "4%",
        }}
      >
        <div
          className="container"
          style={{
            maxWidth: "70%",
            width: "100%",
          }}
        >
          <h2
            style={{
              color: data.sec4.color,
              fontFamily: "Syncopate",
              fontSize: "clamp(1.25rem, 2.5vw, 3rem)",
              fontWeight: 500,
              lineHeight: "3rem",
            }}
          >
            <LiveEdithTextHighLighter section="sec4" type="text">
              {data.sec4.text}
            </LiveEdithTextHighLighter>
          </h2>
          <p
            style={{
              color: data.sec4.color,
              fontFamily: "Montserrat,sans-serif",
              fontSize: "clamp(0.875rem, 1.5vw, 1.5rem)",
              fontWeight: 400,
              lineHeight: "1.75rem",
              paddingBottom:"1rem"
            }}
          >
            <LiveEdithTextHighLighter section="sec4" type="description">
              {data.sec4.description}
            </LiveEdithTextHighLighter>
          </p>
          <a
            style={{
              fontFamily: "Syncopate",
              fontSize: "1.25rem",
              fontWeight: 400,
              lineHeight: "1.3013rem",
              color: "#FFFFFF",
              backgroundColor: "var(--primary)",
              border: "1px solid #FFFFFF",
              borderRadius: 0,
              padding: "0.5rem 1.5rem",
              textDecoration: "none",
            }}
            href={data.sec4?.action?.link || "/blue1/contact"}
            className="btn btn-outline-light"
          >
            {data.sec4.action.text}
          </a>
        </div>
      </div>
    </div>
  );

  const section5 = (
    <div className="sec5pg2" id="sec4">
      <div className="row">
        <div
          className="col-md-6"
          style={{
            backgroundImage: `url(linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${"/public/" + data.sec5.img1
              }) `,
            height: "40vh",
          }}
        >
          <h2
            style={{
              color: data.sec5.color,
              fontFamily: "Syncopate",
              fontWeight: 700,
            }}
          >
            {data.sec5.text1}
          </h2>
        </div>
        <div
          className="col-md-6 img2"
          style={{
            backgroundImage: `url(linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${"/public/" + data.sec5.img2
              }) `,
            height: "40vh",
          }}
        >
          <h2
            style={{
              color: data.sec5.color,
              fontFamily: "Syncopate",
              fontWeight: 700,
            }}
          >
            {data.sec5.text2}
          </h2>
        </div>
      </div>
    </div>
  );

  const section6 = (
    <div
      className="sec6pg2"
      style={{
        backgroundImage: `url(${"/public/" + data.sec6.img}) `,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "12rem 4rem",
      }}
      id="sec4"
    >
      <h2
        style={{
          color: data.sec6.color,
          fontFamily: "Montserrat,sans-serif",
          fontWeight: 700,
        }}
      >
        <LiveEdithTextHighLighter section="sec6" type="text">
          {data.sec6.text}
        </LiveEdithTextHighLighter>
      </h2>
    </div>
  );
  const section7 = (
    <div className="our_team" id="sec5">
      <h1 className="heading">
        <LiveEdithTextHighLighter section="sec7" type="text">
          {data.sec7.text}
        </LiveEdithTextHighLighter>
      </h1>
      <p className="sub-heading" style={{fontFamily:"Montserrat,sans-serif"}}>
        <LiveEdithTextHighLighter section="sec7" type="description">
          {data.sec7.description}
        </LiveEdithTextHighLighter>
      </p>
      <OurTeam section={"sec7"} data={data.sec7.cards} />
    </div>
  );

  const section8 = (
    <div className="sec7pg2" id="sec6">
      <h2 style={{ color: data.sec8.color, fontFamily: "Syncopate" }}>
        <LiveEdithTextHighLighter section="sec8" type="text">
          {data.sec8.text}
        </LiveEdithTextHighLighter>
      </h2>
      <div className="row">
        {data.sec8.cards.map((content, index) => (
          <div className="col-md-4">
            <img src={"/public/" + content.img} alt="" />
            <p
              style={{
                color: content.color,
                fontFamily: "Montserrat,sans-serif",
                marginBottom: "3rem",
              }}
            >
              <LiveEdithTextHighLighter section="sec8" type={`text${index}`}>
                {content.text}
              </LiveEdithTextHighLighter>
            </p>
            <p
              style={{
                color: content.color,
                fontFamily: "Montserrat,sans-serif",
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              <LiveEdithTextHighLighter section="sec8" type={`subtext${index}`}>
                {content.subtext}
              </LiveEdithTextHighLighter>
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  // const section9 = (
  //   <div
  //     className="sec9"
  //     style={{
  //       position: "relative",
  //       backgroundImage: `url(${"/public/" + footerData.sec9.image})`,
  //       backgroundSize: "cover",
  //       backgroundAttachment: "fixed",
  //     }}
  //     id="sec7"
  //   >
  //     {/* Overlay */}
  //     <div
  //       style={{
  //         position: "absolute",
  //         top: 0,
  //         left: 0,
  //         width: "100%",
  //         height: "100%",
  //         backgroundColor: "rgba(0, 0, 0, 0.3)",
  //         zIndex: 1,
  //       }}
  //     ></div>

  //     {/* Content */}
  //     <div
  //       className="container"
  //       style={{
  //         position: "relative",
  //         zIndex: 2,
  //         padding: "0 20px",
  //         textAlign: "center",
  //       }}
  //     >
  //       <h2
  //         style={{
  //           color: footerData.sec9.color,
  //           fontFamily: "Syncopate",
  //           textTransform: "uppercase",
  //           marginBottom: "1rem",
  //           textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
  //         }}
  //       >
  //         {footerData.sec9.text}
  //       </h2>
  //       <p
  //         style={{
  //           color: footerData.sec9.color,
  //           fontFamily: "Garamond",
  //           marginBottom: "1.5rem",
  //           textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
  //         }}
  //       >
  //         {footerData.sec9.description}
  //       </p>
  //       <a
  //         href={footerData.sec9?.action?.link || "/blue1/contact"}
  //         className="btn btn-outline-light"
  //       >
  //         {footerData.sec9.action.text}
  //       </a>
  //     </div>
  //   </div>
  // );
  const section9 = (
    <div
      className="sec9"
      style={{
        position: 'relative',
        backgroundImage: `url(${'/public/' + footerData?.sec9.image})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      }}
      id="sec7">
      {/* Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1,
        }}></div>

      {/* Content */}
      <div
        className="container"
        style={{
          position: 'relative',
          zIndex: 2,
          padding: '0 20px',
          textAlign: 'center',
        }}>
        <h2
          style={{
            color: footerData?.sec9.color,
            fontFamily: 'Syncopate',
            textTransform: 'uppercase',
            marginBottom: '1rem',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
          }}>
          {footerData?.sec9.text}
        </h2>
        <p
          style={{
            color: footerData?.sec9.color,
            fontFamily: 'Montserrat,sans-serif',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
            margin:"auto",
            maxWidth:"60rem",
            minWidth:"40rem",

          }}>
          {footerData?.sec9.description}
        </p>
        <a
          href={footerData?.sec9.action.link || "/blue1/contact"}
          className='btn btn-outline-light'>
          {footerData?.sec9.action.text}
        </a>
      </div>
    </div>
  );

  const content = (
    <div className="template1" style={{ height: "100%" }}>
      <style>{customStyles}</style>
      <Header data={data.header}></Header>
      {data.sec1.visible && section1}
      {data.sec2.visible && section2}
      {data.sec3.visible && section3}
      {data.sec4.visible && section4}
      {/* {data.sec5.visible && section5} */}
      {/* {data.sec6.visible && section6} */}
      {data.sec7.visible && section7}
      {data.sec8.visible && section8}
      {footerData && footerData?.sec9.visible && section9}
      {footerData && (
        <Footer
          {...{
            footerData: footerData && footerData.footer,
          }}
        ></Footer>
      )}
    </div>
  );
  return (
    <div>
      <HelmetSEO
        seoHomeData={seoHomeData}
        profile={profile}
        defaultDescription={defaultDescription}
        defaultKeywords={defaultKeywords}
      />
      <SiteBuilder
        {...{
          template: content,
          siteBuilder: siteBuilder,
          data,
          setData,
        }}
      ></SiteBuilder>
    </div>
  );
}
